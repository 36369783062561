/** @format */
// @flow
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import css from './Modal.scss';
import classnames from 'classnames';
import { Button } from './buttons';
import { Tab, TabList, Tabs } from './UnderlinedTabs';
import { FlexSeparator } from './FlexSeparator';
import { useUnlockedSteps } from '../hooks/useUnlockedSteps';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';

type ModalProps = {
  overlayClassName?: string,
  title?: string,
  children: ?React.Node,
  isOpen?: boolean,
  onRequestClose: (SyntheticEvent<>) => any,
  titleClassName?: string,
  contentClassName?: string,
  titleStyle?: Object,
  className?: string,
} & React.ElementConfig<typeof ReactModal>;

export default function Modal({
  overlayClassName,
  isOpen = true,
  onRequestClose,
  title,
  modalStyle,
  titleClassName,
  contentClassName,
  titleStyle,
  children,
  className,
  onBackButton,
  backButtonText,
  ...props
}: ModalProps) {
  return (
    <ReactModal
      isOpen={isOpen}
      overlayClassName={classnames(css.Overlay, overlayClassName)}
      className={classnames(css.Modal, className)}
      id={'DOM'}
      onRequestClose={onRequestClose}
      {...props}
    >
      {title ? (
        <div className={classnames(css.top, titleClassName)} >
          <h1 className={css.title}>{title}</h1>
          <FlexSeparator />
          {onBackButton ? (
            <button className={css.close} style={{ fontSize: 18 }} onClick={onBackButton}>
              <span>
                <FontAwesomeIcon icon={faArrowLeft} /> {backButtonText}
              </span>
            </button>
          ) : null}
          <button className={css.close} onClick={onRequestClose}>
            <span aria-hidden="true">×</span>
          </button>
        </div>
      ) : null
      }
      <div className={classnames(css.content, contentClassName)}>{children}</div>
    </ReactModal >
  );
}

export function PromptModal({
  title,
  children,
  isOpen = true,
  onRequestClose,
  overlayClassName,
  className,
  shouldCloseOnOverlayClick,
  buttons = (
    <Button style={{ marginLeft: 'auto' }} onClick={onRequestClose}>
      Done
    </Button>
  ),
}) {
  return (
    <ReactModal
      isOpen={isOpen}
      overlayClassName={classnames(css.flexOverlay, overlayClassName)}
      className={classnames(css.prompt, className)}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    >
      <h2 className={css.promptTitle}>
        {title}
        <button className={css.promptClose} onClick={onRequestClose}>
          <span aria-hidden="true">×</span>
        </button>
      </h2>
      <div className={css.promptContent}>{children}</div>
      <div className={css.promptButtonBar}>{buttons}</div>
    </ReactModal>
  );
}

export function TabsModal({
  tabs,
  children,
  isOpen = true,
  onRequestClose,
  overlayClassName,
  tabsClassName,
  className,
  style,
  buttons,
  shouldCloseOnOverlayClick = false,
  ...tabsProps
}) {
  return (
    <ReactModal
      isOpen={isOpen}
      overlayClassName={classnames(css.flexOverlay, overlayClassName)}
      className={classnames(css.prompt, className)}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    >
      <Tabs {...tabsProps} className={tabsClassName}>
        <TabList className={css.tabsList}>
          {tabs}
          <span onClick={onRequestClose} className={css.promptClose}>
            &times;
          </span>
        </TabList>
        {children}
        <div className={css.promptButtonBar}>{buttons}</div>
      </Tabs>
    </ReactModal>
  );
}
