/** @format */

import React from 'react';
import { hot } from 'react-hot-loader/root';
import { Route } from 'react-router-dom';
import TeacherMenus from './TeacherMenus';
import GraphQLValidationNotification from './GraphQLValidationNotification';
import { GetStartedModal } from './GetStarted';
import { SubscriptionRoute } from './SubscriptionRoute';
import { SUBSCRIPTION } from '../../js/teacher-pages/teacher-subscriptions';
import { AnimatedSwitch } from '../AnimatedSwitch';

import {
  CognitiveVerbCreatePage,
  CognitiveVerbPage,
  DashboardPage,
  DiagnosticTestsPage,
  FAQPage,
  FSPage,
  HelpPage,
  ManageGroupsPage,
  ManageStudentsPage,
  NAPLANPage,
  PracticeTrackingPage,
  QCSPage,
  TeacherNotificationsPage,
  TeacherTheoryPage,
  WritingTasksPage,
  SPTrackPage,
  CVSDTrackRoute,
} from './SPARoutes';
// eslint-disable-next-line import/no-named-as-default
import TeacherErrorBoundary from './TeacherErrorBoundary';
import useBannerNotification from '../maintenance-banner';

function TeacherApp({}) {
  useBannerNotification();
  return (
    <>
      <TeacherMenus />
      <GetStartedModal />
      <TeacherErrorBoundary>
        <AnimatedSwitch>
          {/* Pages outside of the SPA that live underneath /activities must have an empty route */}
          <Route path="/activities/cv/overview" />
          <Route path="/activities/cv/present" />
          <Route path="/activities/cv/results" />
          <Route path="/activities/qcs/results" />
          <Route path="/activities/writing/marking" />
          <Route path="/track/programs/student-view" />

          <SubscriptionRoute
            subscription={SUBSCRIPTION.CV}
            path="/activities/cv/create"
            component={CognitiveVerbCreatePage}
          />
          <SubscriptionRoute
            past
            subscription={SUBSCRIPTION.CV}
            path="/activities/cv"
            component={CognitiveVerbPage}
          />
          <SubscriptionRoute
            subscription={SUBSCRIPTION.FS}
            path="/activities/fs/theory"
            component={TeacherTheoryPage}
          />
          <SubscriptionRoute
            past
            subscription={SUBSCRIPTION.FS}
            path="/activities/fs"
            component={FSPage}
          />
          <Route path="/activities/diagnostic" component={DiagnosticTestsPage} />
          <Route path="/activities/naplan" component={NAPLANPage} />
          <Route path="/activities/writing" component={WritingTasksPage} />
          <Route path="/activities/qcs" component={QCSPage} />
          <Route path="/school-management/student" component={ManageStudentsPage} />
          <Route path="/school-management/group" component={ManageGroupsPage} />

          <SubscriptionRoute
            subscription={SUBSCRIPTION.FS}
            past
            exact
            path="/track/usage"
            component={PracticeTrackingPage}
          />
          <Route exact path="/" component={DashboardPage} />
          <Route path="/track/programs" component={SPTrackPage} />
          <Route path="/track/cs-sd" component={CVSDTrackRoute} />
          <Route exact path="/faq" component={FAQPage} />
          <Route exact path="/help" component={HelpPage} />
          <Route exact path="/notifications" component={TeacherNotificationsPage} />
        </AnimatedSwitch>
      </TeacherErrorBoundary>
      <GraphQLValidationNotification />
    </>
  );
}

export default hot(TeacherApp);
