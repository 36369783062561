/** @format */

import React, { useState } from 'react';
import css from './TeacherHeader.scss';
import { HeaderProfile } from './HeaderProfile';
import { useTeacherContext } from '../../js/teacher-pages/context';
import { NotificationBell } from './NotificationBell';
import { HeaderDropdown } from './HeaderDropdown';
import { Notifications } from '../../pages/Dashboard/TeacherHomePage';
import NavLink from './NavLink';
import ArrowLinkList from '../ArrowLinkList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSchool } from '@fortawesome/free-solid-svg-icons/faSchool';
import { faQuestion } from '@fortawesome/free-solid-svg-icons/faQuestion';

function HeaderSearch() {
  return <div className={css.search}></div>;
}

function SchoolManagement() {
  const {
    user: { IsSchoolAdmin }
  } = useTeacherContext();
  return (
    <HeaderDropdown
      className={css.squareSegment}
      dropdown={
        <ArrowLinkList style={{ padding: 20 }}>
          <NavLink to="/school-management">Manage school details</NavLink>
          {IsSchoolAdmin ? (
            <NavLink to="/school-management/staff-management">Manage staff</NavLink>
          ) : null}
          <NavLink to="/school-management/student">Manage students</NavLink>
          <NavLink to="/school-management/group">Manage student groups</NavLink>
        </ArrowLinkList>
      }
    >
      <FontAwesomeIcon icon={faSchool} style={{ color: 'white' }} />
    </HeaderDropdown>
  );
}


function HeaderHelp() {
  return (
    <HeaderDropdown
      className={css.squareSegment}
      dropdown={
        <ArrowLinkList style={{ padding: 20 }}>
          <NavLink to="/help">Guides and Demonstrations</NavLink>
          <NavLink to="/faq">Frequently Asked Questions</NavLink>
        </ArrowLinkList>
      }
    >
      <FontAwesomeIcon icon={faQuestion} style={{ color: 'white' }} />
    </HeaderDropdown>
  );
}

function NotificationDropdown() {
  const [isOpen, setOpen] = useState(false);
  return (
    <HeaderDropdown
      isOpen={isOpen}
      onOpen={setOpen}
      className={css.squareSegment}
      dropdown={
        <div style={{ width: 400 }}>
          <Notifications
            style={{ height: 500, maxHeight: 'calc( 100vh - 70px )' }}
            onClickViewAll={() => setOpen(false)}
          />
        </div>
      }
    >
      <NotificationBell width={23} />
    </HeaderDropdown>
  );
}

export default function TeacherHeader() {
  return (
    <header className={css.header}>
      <HeaderSearch />
      <HeaderHelp />
      <NotificationDropdown />
      <SchoolManagement />
      <HeaderProfile />
    </header>
  );
}
