/** @format */

import { useEffect, useMemo, useState, useRef } from 'react';
import { getActiveMenu } from '../../lib/routes';

export default function useNavigationState({ onPageChange, location }) {
  const firstTime = useRef(true);
  const activeMenu = useMemo(() => getActiveMenu(location.pathname), [location.pathname]);
  const [openMenus, setOpenMenus] = useState(activeMenu ? { [activeMenu]: true } : {});

  useEffect(() => {
    if (firstTime.current && location.pathname === '/') {
      setOpenMenus({ 'Tests & Activities': true });
      onPageChange && onPageChange('Tests & Activities');
    } else {
      setOpenMenus(activeMenu ? { [activeMenu]: true } : {});
      onPageChange && onPageChange(activeMenu);
    }
    firstTime.current = false;
  }, [location.pathname]);

  return [openMenus, setOpenMenus];
}
