
jQuery(document).ready(($) => {
    // ping our server every 3 minutes as teachers leave browser open for hours then get confused when ajax won't load due to being logged out
    setInterval(() => { $.ajax({ url: '/Security/ping' }); }, 180 * 1000);

    // HELP VIDEOS
    ConfigureHelpButtons();

    $('.text-menu a').click(function () {
        $('.text-menu a').removeClass('active');
        $(this).addClass('active');
    });

    // TEACHER DOWNLOAD MARKING ITEM
    $('.MarkingItemTitle').fancybox({ type: 'ajax', wrapCSS: 'MarkingItemDetails', padding: 5 });
});

function ConfigureHelpButtons() {
    jQuery('a.HelpVideo').each((_, elem) => {
        if (elem.href[0] !== '#') {
            elem.href = '#' + elem.href.split('#')[1];
        }
    }).fancybox({ wrapCSS: 'HelpVideoFancybox',
padding: 5,
        afterShow() {
            $(this.content[0]).find('video')[0].play();
        }
    });
}
