/** @format */
import {
  trafficGreen as green,
  trafficOrange as orange,
  trafficRed as red
} from '../css/_globals.scss';
import hslToRgb from './hslToRgb';
import rgbToHsl from './rgbToHsl';

const colours = [
  { colour: toHsl(red), position: 0 },
  { colour: toHsl(red), position: 20 },
  { colour: toHsl(orange), position: 40 },
  { colour: toHsl(orange), position: 60 },
  { colour: toHsl(green), position: 80 },
  { colour: toHsl(green), position: 100 }
];

const clamp = x => Math.max(0, Math.min(1, x));

function gradient(colours, percent) {
  let section = 0;
  while (section + 1 < colours.length && colours[section + 1].position < percent) {
    section++;
  }
  const left = colours[section];
  const right = colours[section + 1];

  const weight = clamp((percent - left.position) / (right.position - left.position));
  const h = left.colour[0] * (1 - weight) + right.colour[0] * weight;
  const s = left.colour[1] * (1 - weight) + right.colour[1] * weight;

  const l = left.colour[2] * (1 - weight) + right.colour[2] * weight;
  return [h, s, l];
}
function rgbToString([r, g, b]) {
  return `rgb(${r},${g},${b})`;
}

function toHsl(col) {
  return rgbToHsl(
    Number.parseInt(col.slice(1, 3), 16),
    Number.parseInt(col.slice(3, 5), 16),
    Number.parseInt(col.slice(5, 7), 16)
  );
}

const colourFromPercent = percent => rgbToString(hslToRgb(...gradient(colours, percent)));

export default colourFromPercent;
