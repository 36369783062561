/** @format */

import React from 'react';

const colours = [
  '#80BD0C',
  '#1ACEA9',
  '#00ADEE',
  '#1E79EF',
  '#794AFF',
  '#FE4C93',
  '#F63535',
  '#EE6F00',
  '#F5B400',
  '#023569'
];

function initials(user) {
  if (__DEV__) {
    const cat = () =>
      String.fromCharCode(55357, Math.floor(Math.random() * (56896 - 56888)) + 56888);
    // prettier-ignore
    switch (parseInt(user.ID)) { // Custom Emojis authorised by J. McCabe 2019-06-28 14:00
      case   233: return "🍆"; // Isaac
      case 51844: return "🕺"; // Jonty
      case 99858: return cat(); // Mason
      case 39325: return "👾️"; // Josh
      case 2221: return "🧀️"; // Steve
    }
  }
  return (
    (user.FirstName && user.FirstName.length ? user.FirstName[0] : '') +
    (user.Surname && user.Surname.length ? user.Surname[0] : '')
  );
}

export default function TeacherInitials({ user, style, className }) {
  return (
    <div
      style={{
        display: 'inline-block',
        backgroundColor: colours[user.ID % colours.length],
        color: 'white',
        borderRadius: 5,
        height: 36,
        width: 36,
        lineHeight: '36px',
        textAlign: 'center',
        fontWeight: 600,
        fontSize: '16px',
        marginRight: 5,
        marginLeft: 5,
        verticalAlign: 'middle',
        ...style
      }}
      title={user.FirstName + ' ' + user.Surname}
      className={className}
    >
      {initials(user)}
    </div>
  );
}
