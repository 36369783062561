/** @format */

import css from './TeacherHeader.scss';
import TeacherInitials from './CurrentTeacherInitials';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import ArrowLinkList from '../ArrowLinkList';
import { useTeacherContext } from '../../js/teacher-pages/context';
import { borderGrey } from 'Globals';
import { HeaderDropdown } from './HeaderDropdown';
import NavLink from './NavLink';

export function HeaderProfile() {
  const { user } = useTeacherContext();

  return (
    <HeaderDropdown
      className={css.headerProfile}
      dropdown={
        <>
          <ArrowLinkList className={css.profileMenuFirstLinks}>
            <NavLink to="/profile">My profile</NavLink>
            <NavLink target="_blank" to="/portal-licence-agreement">License agreement</NavLink>
            <a target="_blank" href="http://mightyminds.com.au/privacy-policy">Privacy policy</a>
          </ArrowLinkList>
          <div style={{ width: '100%', height: 1, backgroundColor: borderGrey }} />
          <ArrowLinkList className={css.profileMenuSecondLinks}>
            <a href="/Security/changepassword">Change password</a>
            <a href="/logout">Log out</a>
          </ArrowLinkList>
        </>
      }
    >
      <TeacherInitials />
      <span className={css.headerProfileName}>
        {user.FirstName} {user.Surname}
      </span>
      <FontAwesomeIcon icon={faChevronDown} />
    </HeaderDropdown>
  );
}

// export default connect(
//   ({ user, header: { dropdowns } }) => ({
//     user,
//     isOpen: dropdowns.length && dropdowns[0] === 'profile'
//   }),
//   dispatch => ({
//     onClick: () => dispatch({ type: 'OPEN_PROFILE_DROPDOWN' }),
//     onClose: () => dispatch({ type: 'CLOSE_PROFILE_DROPDOWN' })
//   })
// )(HeaderProfile);
