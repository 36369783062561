/** @format */

import { useEffect } from 'react';

export default function useTitle(title) {
  // Cannot give this [title] as inputs as route components aren't unmounted if their fade-out
  // animation doesn't complete before the user navigates back to them.
  useEffect(
    () => {
      document.title = `${title} » Mighty Minds Portal`;
    }
  );
}
