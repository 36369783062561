/** @format */

import { formatToTimeZone } from 'date-fns-timezone/src/formatToTimeZone';

/**
 * Converts a date received from SilverStripe into a javascript date object.
 *
 * Javascript always stores dates in UTC internally but its external API
 * pretends to be in the browser's current timezone. The server will always give
 * dates in its own timezone, and this function makes sure javascript knows that
 * when it's parsing the string.
 *
 * @param string
 * @returns {null|Date}
 */
export function ssToJavascriptDate(string) {
  if (!string) {
    return null;
  }
  if (string.length === 10) {
    return new Date(string + 'T00:00:00.000+10:00');
  }
  return new Date(string.replace(' ', 'T') + '.000+10:00');
}

/**
 * SilverStripe likes to call this RFC2822. I'm pretty sure it's not, actually.
 * @type {string}
 */
export const databaseFormat = 'YYYY-MM-DD HH:mm:ss';

/**
 * Converts a javascript date into a string in the format that SilverStripe calls "RFC2822",
 * which is the format that SilverStripe and the mysql database both expect. No matter the
 * browser's current time zone, this will output a time in our server's timezone so that our
 * server never needs to do timezone calculations.
 *
 * @param date
 * @returns {string}
 */
export function javascriptToSsDate(date) {
  return formatToTimeZone(date, databaseFormat, { timeZone: 'Australia/Brisbane' });
}
