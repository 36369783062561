/** @format */

import React from 'react';
import { render } from 'react-dom';
import reducer from './teacher-pages/reducer/reducer';
import configureStore from '../lib/configureStore';
import { Provider as ReduxProvider } from 'react-redux';
import { TeacherContextProvider } from './teacher-pages/context';
import { BrowserRouter as Router } from 'react-router-dom';
import TeacherApp from '../components/TeacherSPA/TeacherApp';
import Modal from 'react-modal';
import { notificationPopup } from '../lib/notificationPopup';
import { SnackbarProvider } from 'notistack';
import { MathJaxContextProvider } from '../hooks/useMathJax';
import { CompatRouter } from 'react-router-dom-v5-compat';

export const boot = () => {
  const appElem = document.getElementById('teacher-react-root');

  if (appElem) {
    document.addEventListener('DOMContentLoaded', () => {
      if (window.mm) {
        const store = configureStore(
          reducer,
          undefined,
          {
            user: window.mm.user,
            message: window.mm.message,
          },
          window.__OR__.reduxMiddleware
        );

        render(
          <ReduxProvider store={store}>
            <SnackbarProvider
              maxSnack={3}
              anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            >
              <TeacherContextProvider>
                <MathJaxContextProvider>
                  <Router>
                    <CompatRouter>
                      <TeacherApp />
                    </CompatRouter>
                  </Router>
                </MathJaxContextProvider>
              </TeacherContextProvider>
            </SnackbarProvider>
          </ReduxProvider>,
          appElem
        );
      }
    });

    Modal.setAppElement(appElem);

    notificationPopup();
  }
};
