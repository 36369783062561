/** @format */

import React, { forwardRef } from 'react';
import { isString } from 'lodash/fp';
import classnames from 'classnames';

export const style = (Component, className) => {
  const fn = className instanceof Function;
  const styled = forwardRef(({ className: classNameProp, as, ...rest }, ref) => {
    const ActualComponent = as || Component;
    return (
      <ActualComponent
        className={classnames(fn ? className(rest) : className, classNameProp)}
        {...rest}
        ref={ref}
      />
    );
  });
  if (__DEV__) {
    styled.displayName = `styled(${
      isString(Component) ? Component : Component.displayName ?? 'Component'
    },${isString(className) ? className : '<function>'})`;
  }
  return styled;
};
