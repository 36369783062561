/** @format */

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MMLogo from '../../icon/MMLogo';
import TeacherInitials from './CurrentTeacherInitials';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { headerBackground } from '../../css/teacher-spa.scss';
import NavLink from './NavLink';

export default function TeacherHeaderMobile() {
  return (
    <div
      style={{
        backgroundColor: headerBackground,
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: 40,
        textAlign: 'center',
        color: 'white',
        lineHeight: '40px',
        verticalAlign: 'middle',
        zIndex: 160
      }}
    >
      <NavLink to="/" style={{ color: 'white' }}>
        <MMLogo style={{ height: 30, verticalAlign: 'middle' }} />
      </NavLink>
      <span style={{ position: 'absolute', right: 0 }}>
        <TeacherInitials style={{ height: 30, width: 30, lineHeight: '30px', fontSize: '15px' }} />
        <FontAwesomeIcon icon={faChevronDown} />
      </span>
    </div>
  );
}
