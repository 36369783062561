/** @format */

import React from 'react';

const path =
  'M6.8,4.5L11,7.3c-9.2,3.4-8.2,14.1-1,17.3c4.6,1.8,12.8,0.4,12.7-9.3l1.3,0.9l1.2-0.9 c-0.2,9.7,8.1,11.2,12.7,9.3c7.3-3.2,8.3-13.9-1-17.3l4.2-2.8c8.1,5.1,8.2,13.9,4.1,19.5c-4.2,5.9-14.4,8.5-21.4,0.7 C17,32.4,6.8,29.9,2.6,24C-1.4,18.4-1.4,9.6,6.8,4.5z M13.8,0H3.5l20.2,15.1L13.8,0z M24.6,15.1L44.8,0H34.4L24.6,15.1z M30.3,3.2 c-4.2-1.6-8.4-1.5-12.6-0.1L24,14.6L30.3,3.2z M34.3,10.7c-3.2,0-5.7,2.6-5.7,5.7c0,3.1,2.6,5.7,5.7,5.7c3.1,0,5.7-2.6,5.7-5.7 C40,13.3,37.5,10.7,34.3,10.7L34.3,10.7z M32.3,16.4c0,1.1,0.9,2,2,2c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2 C33.2,14.4,32.3,15.3,32.3,16.4z M24,32.9l2.3-6l-0.6-0.5L24,31l-1.8-4.6l-0.5,0.4L24,32.9z M13.6,10.4c-3.1,0-5.7,2.6-5.7,5.7 c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7C19.4,13,16.8,10.4,13.6,10.4L13.6,10.4z M11.6,16.1c0,1.1,0.9,2,2,2s2-0.9,2-2 c0-1.1-0.9-2-2-2S11.6,15,11.6,16.1z';

export default React.memo(({ style }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 47.9 32.9"
    style={{ fill: 'currentColor', ...style }}
  >
    <style type="text/css">{'.st0 { fill-rule:evenodd;clip-rule:evenodd; }'}</style>
    <path className="st0" d={path} />
  </svg>
));
