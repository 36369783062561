/** @format */

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '../logging/log';

import 'what-input'; // Used for foundation
import Foundation from 'foundation-sites';
import 'react-hot-loader/patch';

import '../css/global-portal.scss';
import '../css/reset.scss';
import '../css/typography.scss';
import '../css/layout.scss';
import '../css/form.scss';
import '../css/portaloverride.scss';
import '../css/foundation-portal.scss';

import './global-portal';
import './script';
import './portaloverride';
import './foundation-portal';

import { boot } from './teacherboot';

Foundation.addToJquery($);

boot();
