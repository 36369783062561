/** @format */

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { mmArrowRightFat } from '../icon/mmArrowRightFat';
import css from './ArrowLinkList.scss';
import classnames from 'classnames';

/**
 * Places all of its children in a ul with arrows before them.
 * It's meant to be used with links as children, and so will
 * put the arrow elements inside
 */
const ArrowLinkList = ({ className, itemClassName, style, children }) => (
  <ul className={className} style={style}>
    {React.Children.map(
      children,
      child =>
        React.isValidElement(child) ? (
          <li className={classnames(css.link, itemClassName)}>
            {React.cloneElement(child, {
              children: (
                <>
                  <FontAwesomeIcon icon={mmArrowRightFat} style={{ marginRight: 12 }} />
                  {child.props.children}
                </>
              )
            })}
          </li>
        ) : (
          child
        )
    )}
  </ul>
);
export default ArrowLinkList;
