/** @format */

import React, { useCallback, useRef, useState } from 'react';
import css from './TeacherMenus.scss';
import { animated, useSpring } from 'react-spring';
import { withRouter } from 'react-router-dom';
import useSizes from '../../hooks/useSizes';
import useOnClickOutside from 'use-onclickoutside';
import { fast } from '../../lib/animationConfigs';
import { navBreakpointMedium, navWidthCollapsed, navWidthFull } from '../../css/teacher-spa.scss';
import SiteTree from './SiteTree';
import useNavigationState from './useNavigationState';
import { GetStartedButton } from './GetStarted';
import { TeacherNavigationFooter } from '../TeacherNavigationFooter';
import { HomeButton } from './HomeButton';

const medium = parseInt(navBreakpointMedium.slice(0, -2));

function TeacherNavigation({ location }) {
  const collapsible = useSizes(({ width }) => width <= medium);
  const [collapsed, setCollapsed] = useState(true);

  const [openMenus, setOpenMenus] = useNavigationState({
    location,
    onPageChange: () => setCollapsed(true)
  });

  const closed = collapsible && collapsed;
  const toggle = useCallback(
    title => {
      if (closed) {
        setCollapsed(false);
        setOpenMenus({ [title]: true });
      } else {
        setOpenMenus(open => {
          const { [title]: isAlreadySelected, ...rest } = open;
          return isAlreadySelected ? rest : { ...open, [title]: true };
        });
      }
    },
    [closed]
  );

  const clickOutsideRef = useRef();
  useOnClickOutside(clickOutsideRef, collapsible && !collapsed && (() => setCollapsed(true)));

  const style = useSpring({ width: closed ? navWidthCollapsed : navWidthFull, config: fast });

  return (
    <animated.div className={css.leftBar} style={style} ref={clickOutsideRef}>
      <HomeButton collapsed={closed} />
      <GetStartedButton collapsed={closed} />
      <SiteTree open={openMenus} onClick={toggle} collapsed={closed} />
      <TeacherNavigationFooter collapsed={closed} />
    </animated.div>
  );
}

export default withRouter(TeacherNavigation);
