/** @format */

import React, { useEffect, createContext, useRef, useCallback, useMemo, useContext } from 'react';

const Context = createContext();

export function MathJaxContextProvider({ children }) {
  const promise = useRef(Promise.resolve());

  const typesetPromise = useCallback((ref) => {
    promise.current = promise.current.then(() => {
      if (ref && ref.current) {
        return MathJax.typesetPromise([ref.current]);
      }

      return MathJax.typesetPromise();
    });
  }, []);

  const value = useMemo(() => ({ typesetPromise }), [typesetPromise]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export default function useMathJax(inputs = []) {
  const { typesetPromise } = useContext(Context) ?? {};

  // We have to set a timeout because useEffect is going off before the elements exist on the page.
  useEffect(() => {
    if (!typesetPromise) {
      // For backwards compatibility
      process.nextTick(() => MathJax.typesetPromise());
      console.warn('Please wrap useMathJax in a MathJax context');
    } else {
      process.nextTick(typesetPromise);
    }
  }, [inputs, typesetPromise]);
}

export function useMathJaxRef(ref, inputs) {
  const { typesetPromise } = useContext(Context) ?? {};
  useEffect(() => {
    if (ref.current) {
      if (!typesetPromise) {
        // For backwards compatibility
        MathJax.typesetPromise([ref.current]);
        console.warn('Please wrap useMathJax in a MathJax context');
      } else {
        typesetPromise(ref);
      }
    }
  }, [inputs, ref, typesetPromise]);
}
