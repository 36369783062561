import { throttle } from 'lodash/fp';

jQuery(document).ready(($) => {
  const $document = $(document);

  if ($document.foundation) $document.foundation();

  //Site-wide Ajax Reveal Popup by Isaac - September 2018
  //Make an <a class="reveal-ajax" href="___"> link to the ___ URL you want to ajax from
  $(document).ready(function(){
    $(document).on('click','.reveal-ajax', function(e) {
      e.preventDefault();
      const link = $(this).prop('href');
      $.ajax(link)
        .done(function(content) {
          $('#reveal-popup-content').html(content);
          $('#reveal-popup').foundation('open');
        });
    });
  });

  //Site-wide Ajax Reveal Popup by Isaac - September 2018
  //Make an <a class="reveal-ajax" href="___"> link to the ___ URL you want to ajax from
  $(document).ready(function(){
    $(document).on('click','.reveal-ajax', function(e) {
      e.preventDefault();
      const link = $(this).prop('href');
      $.ajax(link)
        .done(function(content) {
          $('#reveal-popup-content').html(content);
          $('#reveal-popup').foundation('open');
        });
    });
  });

  setTimeout(() => {
    $('.message.slideOutUp').slideUp('slow');
  }, 60000);
  $(document).on('click', '.message.slideOutUp', () => {
    $('.message.slideOutUp').slideUp('slow');
  });
  $document.on('keydown', '.label-on-right', (e) => {
    $.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 || /65|67|86|88/.test(e.keyCode) && (!0 === e.ctrlKey || !0 === e.metaKey) || e.keyCode >= 35 && e.keyCode <= 40 || (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105) && e.preventDefault();
  });

  $('#Form_SelectMightyMindsExamSheetForm_action_processSelectMightyMindsExamSheetForm')
    .click(function() {
      $(this).css('background', '#999');
      $(this).hide();
      $('#loading-image').show();
    });

  $('a.order').click(function (e) {
    e.preventDefault();
    const link = $(this).attr('href');
    $.ajax(link).done(function (content) {
      $('#reveal-popup-content').html(content);
      $('#reveal-popup').foundation('open');
    });
  });

  // fancybox({
  //   type: 'ajax',
  //   minWidth: 300
  // });

  // Exam Setup page
  // Exam Setup Page to send emails to students
  $('a.send-autologin-email').fancybox({
    type: 'ajax',
    minWidth: 300
  });

  $('a.start-exam').fancybox({
    type: 'iframe',
    width: 400,
    maxHeight: '95%'
  });

  $('a.marking-help').fancybox({
    type: 'iframe',
    minWidth: 400,
    maxHeight: '90%'
  });

  $('a.ePop').fancybox({
    type: 'ajax',
    autoDimensions: false,
    width: 900,
    height: 600,
    minWidth: 100,
    minHeight: 100,
    maxWidth: 9999,
    maxHeight: 9999

  });

  if(jQuery().chosen) {
      $('select.dropdown:not(.selectize-body):not(.selectize)').chosen({
        allow_single_deselect: true,
        disable_search_threshold: 40
      });

      $('select.listbox').chosen();
  }

  $('.download-buttons p').click(function() {
    const $this = $(this);
    const value = $(this).data('class');

    $('.download-buttons p').removeClass('current');

    $this.addClass('current');

    $('.items').hide();
    $(`div.${value}`).fadeIn('fast');
  });

  /* removes text from search form on focus and replaces it on unfocus - if text is entered then it does not get replaced with default on unfocus */
  $('#SearchForm_SearchForm_action_results').val('L');
  const searchField = $('#SearchForm_SearchForm_Search');
  const default_value = searchField.val();
  searchField.focus(function() {
    $(this).addClass('active');
    if (searchField.val() == default_value) {
      searchField.val('');
    }
  });
  searchField.blur(() => {
    if (searchField.val() == '') {
      searchField.val(default_value);
    }
  });

  if ($('select').length) {
    $.validator.setDefaults({ ignore: ':hidden:not(select)' });
  }

  if ($('#Form_AddUserForm').length) {
    const validator = $('#Form_AddUserForm').validate({
      rules: {
        FirstName: 'required',
        Surname: 'required',

        Email: {
          required: true,
          email: true
        },
        Password: {
          required: true,
          minlength: 8
        }
      },
      messages: {
        FirstName: 'Enter your First Name',
        Surname: 'Enter your Surname',
        Password: '',

        Email: {
          required: 'Please enter a valid email address',
          minlength: 'Please enter a valid email address'
        }
      },
      // errorContainer: container,
      // errorLabelContainer: container,
      wrapper: 'p'

    });
  }


  $('#Form_AddUserForm_Password').on('keyup', (e) => {
    if ($('#Form_AddUserForm_Password').val() == '') {
      $('#passwordStrength').removeClass().html('');
      return false;
    }
    if ($('#Form_AddUserForm_Password').val().length < 8) {
      // If ok regex doesn't match the password
      $('#passwordStrength')
        .removeClass()
        .addClass('alert alert-error')
        .html('Password must be 8 characters long.');
    } else {
      $('#passwordStrength').removeClass().addClass('alert alert-error').html('');
    }
    return true;
  });

  $('#Form_saveUserDetails_Password').on('keyup', (e) => {
    if ($('#Form_saveUserDetails_Password').val() == '') {
      $('#passwordStrength2').removeClass().html('');
      return false;
    }
    if ($('#Form_saveUserDetails_Password').val().length < 8) {
      // If ok regex doesn't match the password
      $('#passwordStrength2')
        .removeClass()
        .addClass('alert alert-error')
        .html('Password must be 8 characters long.');
    } else {
      $('#passwordStrength2').removeClass().addClass('alert alert-error').html('');
    }
    return true;
  });

    //TEACHER PAGES TOP NAV MOUSEOVER SUB-MENU POPUPS - Isaac November 2018
    $('#stickyHeaderMiddle a').mouseover(function(){
        let tab = $(this).data('section');
        ShowSubMenu(tab);
    });
    $('header.header').mouseleave(function(){
        let originaltab = $('#navigation').data('currentnavigationsection');
        ShowSubMenu(originaltab);
    });

    function ShowSubMenu(section) {
        $('#stickyHeaderMiddle a').removeClass('active');
        $('#stickyHeaderMiddle a.text-menu-' + section).addClass('active');
        $('#navigation .layout-buttons').hide();
        $('#navigation .layout-buttons-' + section).show();
    }

    $(document).on('click','.rotatable-image-left, .rotatable-image-right',function(e){
        e.preventDefault();
        let image = $(this).closest('.uploaded-content-container').find('.rotatable-image').first();
        let newangle = image.data('angle') + $(this).data('angle');
        let is90 = (newangle % 180) != 0;
        if($('body').hasClass('StudentMarkCVTestPage')) {
            //image is potentially in two spots so rotate both because mason was cloning element to make the enlargement view
            image = $(image).closest('.page').find('.rotatable-image');
        }
        image.data('angle',newangle);
        image.css('transform', 'rotate(' + newangle + 'deg)');
        image.toggleClass('is90',is90);
        refreshRotatableImages();
        addPaddingToRotatableImage(image);
        return false;
        // :not(.is90) is used to remove padding from images
    });

    // Gives the rotatable image a max width and height calculated by watching the parent
    $(window).resize(throttle(100, refreshRotatableImages));

    function refreshRotatableImages() {
        var containers = $('.rotatable-image:visible');
        containers.each(function (_, container) {
            var $container = $(container);
            var width = $container.parent().width();
            var height = $container.parent().height();
            if ($container.hasClass('is90')) {
                $container.css({'max-height': width});
            } else {
                $container.css({'max-width': width, 'max-height': height});
            }
        });
    }

    // Calculates the width and height - adds padding so that "landscape" images wont overlap text when they rotate
    $(window).resize(throttle(200, addPaddingToAllRotatableImages));

    function addPaddingToAllRotatableImages() {
         $('.rotatable-image:visible').each(function(){
            addPaddingToRotatableImage($(this));
         });
    }

    function addPaddingToRotatableImage($image) {
        var width = $image.width();
        var height = $image.height();
        var the_margin = (width > height ? (width-height)/2 : 0);

        $image.css({paddingTop:`${the_margin}px`,paddingBottom:`${the_margin}px`});
    }

    // Show pop up on Student Exam List Page. Check if it exists.
    if($('body').hasClass('StudentExamListPage')) {
      if ($('#image-notification-modal').length > 0){
         $('#image-notification-modal').foundation('open');
      };
    }
});

