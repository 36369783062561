/** @format */

import { matchPath } from 'react-router-dom';
import { mmResources } from '../icon/mmResources';
import { mmTracking } from '../icon/mmTracking';
import { mmActivities } from '../icon/mmActivities';
import { mmDashboard } from '../icon/mmDashboard';
// import { faBookmark } from '@fortawesome/free-regular-svg-icons';

export const routes = [
  {
    title: 'Dashboard',
    icon: mmDashboard,
    link: '/',
    exact: true,
  },
  {
    title: 'Tests & Activities',
    prefix: 'activities',
    icon: mmActivities,
    children: [
      { title: 'Cognitive Skills', link: '/activities/cv' },
      { title: 'Fundamental Skills', link: '/activities/fs' },
      { title: 'NAPLAN', link: '/activities/naplan' },
      { title: 'Writing Tasks', link: '/activities/writing' },
      { title: 'Diagnostic Tests', link: '/activities/diagnostic' },
      // NOTE: removed as requested - https://mightyminds.atlassian.net/jira/software/projects/CPI/boards/3?selectedIssue=CPI-25
      // {
      //   title: 'Links to Life',
      //   link: '/activities/links-to-life',
      //   requirement: ({ schoolCourses }) => schoolCourses,
      //   icon: faBookmark
      // }
    ],
  },
  {
    title: 'Tracking',
    prefix: 'track',
    icon: mmTracking,
    children: [
      { title: 'Student', link: '/track/students' },
      { title: 'Classes', link: '/track/class' },
      { title: 'Weekly Tasks', link: '/track/weekly' },
      { title: 'Programs', link: '/track/programs/' },
      { title: 'Portal Usage', link: '/track/usage' },
    ],
  },
  {
    title: 'Resources',
    prefix: 'resources',
    icon: mmResources,
    children: [
      { title: 'Mark Packs', link: '/resources/mark-packs' },
      { title: 'Purchase Resources', link: '/resources/shop' },
      { title: 'Owned Resources', link: '/resources/owned' },
      { title: 'Workshop Resources', link: '/resources/workshops' },
    ],
  },
];

export function getActiveMenu(pathname) {
  const {
    params: { action },
  } = matchPath(pathname, '/:action?');
  const r = routes.find(({ prefix }) => prefix && prefix === action);
  return r?.title;
}

function urlInSPABlacklist(url) {
  return [
    '/track/programs/student-view',
    '/activities/cv/overview',
    '/activities/cv/present',
    '/activities/cv/results',
    '/activities/links-to-life',
    '/activities/qcs/results',
    '/activities/writing/marking',
  ].some((blacklist) => url.startsWith(blacklist));
}

export function urlInSPAWhitelist(url) {
  // These are temporarily outside the spa but are underneath /activities
  if (urlInSPABlacklist(url)) return false;
  return (
    url === '/' ||
    url === '/track/usage' ||
    url === '/help' ||
    url === '/faq' ||
    url === '/notifications' ||
    Boolean(matchPath(url, { path: '/track/programs/' })) ||
    Boolean(matchPath(url, { path: '/track/cs-sd/' })) ||
    Boolean(matchPath(url, { path: '/activities' })) ||
    Boolean(matchPath(url, { path: '/school-management/:type(student|group)' }))
  );
}
