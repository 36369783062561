/** @format */

import { useEffect, useState, useRef } from 'react';
import { throttle } from 'lodash/fp';

function getSize() {
  return {
    height: window.innerHeight,
    width: window.innerWidth
  };
}

export default function useSizes(mapSizeToState) {
  const [state, setState] = useState(mapSizeToState(getSize()));
  const last = useRef(state);

  useEffect(() => {
    const handleResize = throttle(200, () => {
      const next = mapSizeToState(getSize());
      if (next !== last.current) {
        last.current = next;
        setState(next);
      }
    });

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return state;
}
