/** @format */
import { useTeacherContext } from './context';

export class TeacherSubscriptionError extends Error {
  constructor(sub) {
    super(`School does not have permissions for ${sub}`);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, TeacherSubscriptionError);
    }

    this.name = 'TeacherSubscriptionError';
    this.subscription = sub;
  }
}

export function useSubscription(permission, past) {
  const { schoolSubscriptions } = useTeacherContext();
  return (
    schoolSubscriptions && schoolSubscriptions[past ? 'all' : 'current']?.includes(permission)
  );
}

export const SUBSCRIPTION = {
  CV: 'Cognitive Verbs',
  FS: 'Fundamental Skills',
  ALL: 'All'
};

export const SUBSCRIPTION_LONG_DESCRIPTION = {
  'CV': 'Cognitive skills',
  'Cognitive Verbs': 'Cognitive skills',
  'FS': 'Fundamental skills',
  'Fundamental Skills': 'Fundamental skills',
  'ALL': 'Fundamental skills + Cognitive skills',
  'All': 'Fundamental skills + Cognitive skills',
};