/** @format */

import React from 'react';
import { style } from '../../lib/styling';
import css from './Panels.scss';

export const WhitePanel = style('div', css.whitePanel);

export function TitledPanel({ title, directions, children, ...props }) {
  return (
    <WhitePanel {...props}>
      <div className={css.titleContainer}>
        {directions ? (
          <div className={css.titleContainer__stacked}>
            <div className={css.title}>{title}</div>
            <div className={css.directions}>{directions}</div>
          </div>
        ) : (
          title
        )}
      </div>
      {children}
    </WhitePanel>
  );
}

export const FlexWhitePanel = style('div', css.flexWhitePanel);
