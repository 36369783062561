/** @format */
// @flow

import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import type { Store, Reducer } from 'redux';
import type { Saga, SagaMiddleware } from 'redux-saga';

export default function configureStore<S, A>(
  reducer: Reducer<S, A>,
  saga?: () => Saga<*>,
  preloadedState?: any,
  plugin
): Store<S, A> & { sagaMiddleware: SagaMiddleware } {
  const sagaMiddleware = createSagaMiddleware();

  const store: Object = createStore(
    reducer,
    preloadedState,
    composeWithDevTools(
      plugin ? applyMiddleware(sagaMiddleware, plugin) : applyMiddleware(sagaMiddleware)
    )
  );
  if (saga) {
    sagaMiddleware.run(saga);
  }
  store.sagaMiddleware = sagaMiddleware;
  return store;
}
