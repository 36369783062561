/** @format */

import { urlInSPAWhitelist } from '../../lib/routes';
import { matchPath, useLocation, useHistory } from 'react-router-dom';
import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

function isModifiedEvent(event) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

/**
 * A react-router Link wrapper that knows if it's active and whether or not it needs to reload the
 * page when clicked
 */
function NavLink({
  'aria-current': ariaCurrent = 'page',
  to,
  className,
  activeClassName,
  style,
  activeStyle,
  exact,
  strict,
  target,
  replace,
  isActive: isActiveProp,
  children,
  title,
  onNavigate
}) {
  const location = useLocation();
  const history = useHistory();
  const onClick = useCallback(
    event => {
      const mustReload = !(urlInSPAWhitelist(to) && urlInSPAWhitelist(location.pathname));
      if (
        !mustReload && // let browser handle navigation between pages outside the SPA
        event.button === 0 && // ignore everything but left clicks
        (!target || target === '_self') && // let browser handle "target=_blank" etc.
        !isModifiedEvent(event) // ignore clicks with modifier keys
      ) {
        event.preventDefault();

        onNavigate && onNavigate();

        const method = replace ? history.replace : history.push;
        method(to);
      }
    },
    [to, location.pathname]
  );
  const isActive = useMemo(
    () => {
      // Regex taken from: https://github.com/pillarjs/path-to-regexp/blob/master/index.js#L202
      const escapedPath = to && to.replace(/([.+*?=^!:${}()[\]|/\\])/g, '\\$1');
      const match = escapedPath
        ? matchPath(location.pathname, { path: escapedPath, exact, strict })
        : null;

      return Boolean(isActiveProp ? isActiveProp(match, location) : match);
    },
    [location.pathname, to, exact, strict, isActiveProp]
  );
  return (
    <a
      aria-current={(isActive && ariaCurrent) || null}
      className={classnames({
        [className]: true,
        [activeClassName]: isActive
      })}
      style={isActive ? { ...style, ...activeStyle } : style}
      href={to}
      onClick={onClick}
      target={target}
      children={children}
      title={title}
    />
  );
}

if (__DEV__) {
  const ariaCurrentType = PropTypes.oneOf(['page', 'step', 'location', 'date', 'time', 'true']);

  NavLink.propTypes = {
    'aria-current': ariaCurrentType,
    to: PropTypes.string.isRequired,
    activeClassName: PropTypes.string,
    activeStyle: PropTypes.object,
    className: PropTypes.string,
    exact: PropTypes.bool,
    isActive: PropTypes.func,
    strict: PropTypes.bool,
    style: PropTypes.object,
    target: PropTypes.string,
    children: PropTypes.node
  };
}

export default NavLink;
