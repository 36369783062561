/** @format */

import { useCallback, useEffect, useState } from 'react';

export function useUnlockedSteps(steps, { enableOnValidation = false } = {}) {
  const [current, setCurrent] = useState(0);
  const [max, setMax] = useState(0);
  const [maxMax, setMaxMax] = useState(0);

  let newMax = 0;
  let validated = false;
  const stepCount = steps.length;
  for (; newMax < steps.length && steps[newMax]; newMax++) {}
  if (newMax === steps.length) {
    newMax -= 1;
    validated = true;
  }

  const shouldChangeMax = newMax < maxMax || enableOnValidation;
  const currentMax = shouldChangeMax ? newMax : max;

  const updateMax = to => {
    setMax(to);
    setMaxMax(Math.max(to, maxMax));
    if (to < current) setCurrent(to);
  };

  useEffect(() => {
    if (shouldChangeMax) {
      updateMax(currentMax);
    }
  }, [currentMax]);

  const hasNext = newMax > current;
  const hasPrevious = Boolean(current);

  const next = useCallback(() => {
    if (hasNext) {
      setCurrent(current + 1);
      // This will happen when enableOnValidation = false
      if (max < current + 1) {
        updateMax(current + 1);
      }
    }
  }, [hasNext, current, maxMax]);
  const setCurrentBounded = useCallback(index => index <= max && setCurrent(index), [max]);
  const previous = useCallback(() => hasPrevious && setCurrent(current => current - 1), [
    hasPrevious
  ]);
  return {
    current: Math.min(current, newMax),
    max: currentMax,
    hasNext,
    hasPrevious,
    setCurrent: setCurrentBounded,
    next,
    previous,
    validated
  };
}
