export const createActivityLinks = {
  NAPLAN: '/assess/assign-online?type=naplanpractice',
  'Fundamental Skills': '/assess/cyot/',
  'Cognitive Verbs': '/activities/cv/create',
  'Cognitive Skills': '/activities/cv/create',
  'Diagnostic Tests': '/assess/assign-online?type=landndiagnostictesting',
  'Writing Tasks': '/assess/assign-online?type=writingpractice',
  QCS: '/assess/assign-online?type=qcspractice'
};
export const activityListingLinks = {
  NAPLAN: '/activities/naplan/',
  'Fundamental Skills': '/activities/fs/',
  'Cognitive Verbs': '/activities/cv/',
  'Cognitive Skills': '/activities/cv/',
  'Diagnostic Tests': '/activities/diagnostic/',
  'Writing Tasks': '/activities/writing/',
  QCS: '/activities/qcs/'
};