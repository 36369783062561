/** @format */

/* eslint-disable no-console */
import Queue from './Queue';

const queue = new Queue({ endpoint: 'api/log' });

const noop = () => null;

const oldLog = console.log || noop;

const oldInfo = console.info || noop;

const oldWarn = console.warn || noop;

const oldError = console.error || noop;

const oldDebug = console.debug || noop;

const reg = /%s/g;

function getMessage(...args) {
  try {
    if (args.length <= 1) {
      return typeof args[0] === 'object' ? JSON.stringify(args[0]) : args[0] || '';
    }

    const msgs = [args.shift()];

    if (reg.test(msgs[0]) && args.length > 0) {
      msgs[0].replace(reg, () => args.shift());
    }

    args.forEach((arg) => {
      if (typeof arg === 'object') {
        msgs.push(JSON.stringify(arg));
        return;
      }

      msgs.push(arg);
    });

    return msgs.join(' ');
  } catch {
    // ignore
  }
}

function getStack() {
  try {
    throw Error();
  } catch (trace) {
    const splitStack = trace.stack?.split('\n');
    return splitStack.slice(4).join('\n').trim();
  }
}

function makeObject(msg, level) {
  let message;
  let stacktrace;

  if (msg instanceof Error) {
    message = msg.message;
    stacktrace = msg.stack ?? getStack();
  } else {
    const msgArray = Array.isArray(msg) ? msg : [msg];
    message = getMessage(...msgArray);
    stacktrace = getStack();
  }
  return {
    message,
    url: window.location.href,
    level,
    stacktrace,
    version: navigator?.appVersion,
    location: 'FRONTEND',
  };
}

if (console) {
  console.log = (...args) => {
    // NOTE removed as it was flooding the database.
    // const obj = makeObject(args, 'log');
    // queue.queueMsg(obj);
    oldLog(...args);
  };

  console.info = (...args) => {
    const obj = makeObject(args, 'info');
    oldInfo(...args);
    if (__DEV__ && obj.message.includes('WDS')) {
      return;
    }
    queue.queueMsg(obj);
  };
  console.warn = (...args) => {
    const obj = makeObject(args, 'warn');
    oldWarn(...args);
    if (__DEV__ && obj.message.includes('HMR')) {
      return;
    }
    queue.queueMsg(obj);
  };
  console.error = (...args) => {
    const obj = makeObject(args, 'error');
    oldError(...args);
    queue.queueMsg(obj);
  };
  console.debug = (...args) => {
    // NOTE removed as it was flooding the database.
    // queue.queueMsg(obj);
    // const obj = makeObject(args, 'log');
    oldDebug(...args);
  };
}

window.addEventListener('error', (err) => {
  const obj = makeObject(err.error ?? err.message, 'error');
  queue.queueMsg(obj);
});
