/** @format */

import React from 'react';
import { useSubscription } from '../../js/teacher-pages/teacher-subscriptions';
import { Route } from 'react-router-dom';
import { WhitePanel } from '../ActivityHomepage/Panels';
import { primaryColour } from '../../css/_globals.scss';
import { TeacherPageBody } from './TeacherPageBody';

export const DontHaveSubscription = ({ subscription, links, imgLink }) => (
  <TeacherPageBody title={'Oops!'}>
    <WhitePanel style={{ padding: 20 }}>
      {imgLink ? (
        <img src={imgLink} style={{ maxWidth: '100px', float: 'left', 'margin-right': '25px' }} />
      ) : null}
      <p>
        This page requires a <strong>{subscription}</strong> subscription.
      </p>
      {links && links.length ? (
        <>
          <p>To find out more about {subscription}, download the following:</p>
          <ul style={{ marginTop: -10, marginBottom: 15, fontSize: 14 }}>
            {links.map(({ label, url }) => (
              <li>
                <strong>
                  <a href={url}>{label}</a>
                </strong>
              </li>
            ))}
          </ul>
        </>
      ) : null}
      <p>
        <a href="mailto:portal@mightyminds.com.au" style={{ color: primaryColour }}>
          Contact Mighty Minds
        </a>{' '}
        if your school is interested in purchasing a subscription.
      </p>
    </WhitePanel>
  </TeacherPageBody>
);

export function SubscriptionRoute({ subscription, past = false, component: Component, ...rest }) {
  const subscribed = useSubscription(subscription, past);
  return (
    <Route
      {...rest}
      render={(props) =>
        subscribed ? <Component {...props} /> : <DontHaveSubscription subscription={subscription} />
      }
    />
  );
}
