/** @format */

import css from './TeacherMenus.scss';
import { routes } from '../../lib/routes';
import NavLink from './NavLink';
import React from 'react';
import usePrevious from '@rooks/use-previous';
import { useMeasureOnce } from '../../hooks/useMeasure';
import { animated, useSpring } from 'react-spring';
import { fast } from '../../lib/animationConfigs';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { useTeacherContext } from '../../js/teacher-pages/context';

const Tree = function Tree({ title, icon, children, open, collapsed, onClick }) {
  const previous = usePrevious(open);
  const [bind, { height: viewHeight }] = useMeasureOnce();
  const { height, opacity, titleOpacity } = useSpring({
    height: open ? viewHeight : 0,
    opacity: open ? 1 : 0,
    titleOpacity: collapsed ? 0 : 1,
    config: fast
  });

  return (
    <div className={css.navLevelOneContainer}>
      <div
        className={classnames({
          [css.navLevelOne]: true,
          [css.active]: open
        })}
        onClick={() => onClick(title)}
        title={title}
      >
        {icon ? <FontAwesomeIcon icon={icon} style={{ fontSize: 19, marginRight: 10 }} /> : null}
        <animated.a className={css.navLevelOneLink} style={{ opacity: titleOpacity }}>
          {title}
          {collapsed ? null : (
            <FontAwesomeIcon
              style={{ position: 'absolute', right: 25, top: 20 }}
              icon={open ? faChevronUp : faChevronDown}
            />
          )}
        </animated.a>
      </div>
      <animated.div
        style={{
          opacity,
          height: open && previous === open ? 'auto' : height,
          overflow: 'hidden'
        }}
      >
        <div {...bind}>{children}</div>
      </animated.div>
    </div>
  );
};

export default function SiteTree({ open, onClick, collapsed = false }) {
  const context = useTeacherContext();
  return (
    <div className={css.navScroll}>
      {routes.map(({ title, icon, children, link, exact }) =>
        children ? (
          <Tree
            key={title}
            title={title}
            icon={icon}
            open={open[title] && !collapsed}
            collapsed={collapsed}
            onClick={onClick}
          >
            {children.map(({ title, link, exact, requirement }) =>
              requirement && !requirement(context) ? null : (
                <NavLink
                  key={title}
                  to={link}
                  className={css.navLevelTwo}
                  activeClassName={css.active}
                  exact={exact}
                >
                  {title}
                </NavLink>
              )
            )}
          </Tree>
        ) : (
          <NavLink
            className={css.navLevelOneFlatLink}
            to={link}
            activeClassName={css.active}
            exact={exact}
            key={title}
            title={title}
          >
            <FontAwesomeIcon icon={icon} style={{ fontSize: 19, marginRight: 10 }} />{' '}
            {collapsed ? null : title}
          </NavLink>
        )
      )}
    </div>
  );
}
