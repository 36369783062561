/** @format */

import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { TeacherPageBody } from '../../components/TeacherSPA/TeacherPageBody';
import { FlexWhitePanel, WhitePanel } from '../../components/ActivityHomepage/Panels';

import css from './TeacherHomePage.scss';
import { ssToJavascriptDate } from '../../lib/dates';
import format from 'date-fns/format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faCommentDots } from '@fortawesome/free-solid-svg-icons/faCommentDots';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import TeacherInitials from '../../components/TeacherInitials';
import { withSizes } from 'react-sizes';
import ArrowLinkList from '../../components/ArrowLinkList';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import Select from 'react-select';
import { ActionButtonArrow } from '../../components/buttons';
import { lightAccentBackgroundGrey, primaryColour, secondaryTextColour, textColour } from 'Globals';
import Modal from '../../components/Modal';
import NavLink from '../../components/TeacherSPA/NavLink';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import useTitle from '../../hooks/useTitle';
import { fetch } from '../../lib/fetch';
import classnames from 'classnames';
import { activityListingLinks, createActivityLinks } from '../../lib/link-data';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons/faBullhorn';

const loadDataContext = React.createContext({});

function LoadDataCache({ children }) {
  const context = useRef({});
  return <loadDataContext.Provider value={context.current}>{children}</loadDataContext.Provider>;
}

const useLoadData = (endpoint) => {
  const context = useContext(loadDataContext);
  const [data, setData] = useState(context[endpoint]);

  useEffect(() => {
    if (data === undefined) {
      fetch('home/' + endpoint)
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          context[endpoint] = json;
          setData(json);
        });
    }
  }, []);

  return data;
};

const mySessionTypeSelectStyles = {
  indicatorSeparator: () => ({}),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: primaryColour,
    ':hover': {},
  }),
  singleValue: (provided) => ({
    ...provided,
    color: primaryColour,
    fontSize: '16px',
    position: 'initial',
    transform: 'unset',
    paddingLeft: 7,
    maxWidth: 'unset',
  }),
  valueContainer: () => ({ display: 'flex' }),
  control: (provided) => ({
    ...provided,
    display: 'inline-flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: `1px solid ${primaryColour}`,
    '&:hover': {
      boxShadow: '0px 2px 12px -5px #9da1b0',
      cursor: 'pointer',
    },
  }),
  container: (provided) => ({
    ...provided,
    width: 190,
    textAlign: 'center',
  }),
  menu: (provided) => ({ ...provided, textAlign: 'left' }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#eee8ff' : 'transparent',
    color: state.isSelected ? primaryColour : textColour,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: state.isSelected ? '#eee8ff' : lightAccentBackgroundGrey,
    },
  }),
};

function SessionTypeShortName(FullName) {
  console.log('Getting session short name for: ' + FullName);
  switch (FullName) {
    case 'Cognitive Skills':
      return 'CS';
    case 'Fundamental Skills':
      return 'FS';
    case 'Writing Tasks':
      return 'WT';
    case 'Diagnostic Tests':
      return 'diagnostic';
  }
  return FullName;
}

function MySessions() {
  const data = useLoadData('MySessions');

  const [open, setOpen] = useState();

  useEffect(() => {
    if (data) setOpen(data.Sessions[0]);
  }, [data]);

  // NOTE (to Jonty esp.) HMR won't automatically reload the content of this component
  // until you change which type of session is selected
  return useMemo(
    () => (
      <WhitePanel className={css.mySessionsContainer}>
        <div className={css.panelTitle}>
          {open ? <span className={css.week}>My sessions: Week {data.Week} </span> : 'My sessions'}
        </div>
        <div className={css.flexWrapper}>
          <div className={css.mySessionsContainerInner}>
            {open ? (
              <div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Select
                    // menuIsOpen
                    isSearchable={false}
                    options={data.Sessions}
                    getOptionLabel={({ Type }) => Type}
                    getOptionValue={({ Type }) => Type}
                    value={open}
                    onChange={setOpen}
                    styles={mySessionTypeSelectStyles}
                  />
                </div>
                <div className={css.sessionStatusContainer}>
                  <div className={css.sessionStatusContainerInner}>
                    <div className={css.item}>
                      {open.Opening}
                      <span>opening</span>
                    </div>
                    <div className={css.item}>
                      {open.Open}
                      <span>open</span>
                    </div>
                    <div className={css.item}>
                      {open.Completed}
                      <span>completed</span>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className={css.loading}>
                <FontAwesomeIcon className={'fa-spin'} icon={faSpinner} />
              </div>
            )}
          </div>

          <div className={css.mySessionsContainerBottom}>
            <ActionButtonArrow
              style={{ width: '100%', marginBottom: '15px' }}
              to={open ? activityListingLinks[open.Type] : undefined}
            >
              View all {open ? SessionTypeShortName(open.Type) : ''} sessions
            </ActionButtonArrow>

            <ActionButtonArrow
              style={{ width: '100%' }}
              to={open ? createActivityLinks[open.Type] : undefined}
            >
              Create new {open ? SessionTypeShortName(open.Type) : ''} session
            </ActionButtonArrow>
          </div>
        </div>
      </WhitePanel>
    ),
    [data, open]
  );
}

function FeedbackPopup() {
  return <div />;
}

// This is a direct copy of the behaviour of the search from the old dashboard page
function performResourcesSearch(query) {
  fetch('/home/resourceSearch/?query=' + encodeURIComponent(query))
    .then((response) => response.text())
    .then((data) => (window.location.href = data));
}

function ResourcesSearch() {
  const [value, setValue] = useState();
  const onIconClick = useCallback(() => {
    performResourcesSearch(value);
  });
  const onKeyDown = useCallback(
    (e) => {
      if (e.keyCode === 13) {
        performResourcesSearch(value);
      }
    },
    [value]
  );
  return (
    <div id="ResourceSearchContainer" className={css.ResourceSearchContainer}>
      <div className={'fieldIconContainer'} title={`Search`}>
        <FontAwesomeIcon icon={faSearch} className={'fieldIcon'} onClick={onIconClick} />
      </div>
      <input
        type="text"
        id="ResourceSearchField"
        placeholder="Search resources..."
        className={'inputStyle'}
        onKeyDown={onKeyDown}
        onChange={(e) => setValue(e.target.value)}
      />
    </div>
  );
}

function SupportLinks() {
  const [open, setOpen] = useState(false);

  return (
    <WhitePanel className={css.supportContainer}>
      <div className={css.panelTitle}>Support</div>

      <ArrowLinkList className={css.supportLinkList}>
        <NavLink to="/help">
          <span style={{ display: 'inline-block', verticalAlign: 'middle' }}>
            <div style={{ fontWeight: 'bold' }}>Portal help</div>
            <div>Guides and demonstrations</div>
          </span>
        </NavLink>
        <NavLink to="/faq">
          <span style={{ display: 'inline-block', verticalAlign: 'middle' }}>
            <div style={{ fontWeight: 'bold' }}>Frequently asked questions</div>
            <div>Answers to common questions</div>
          </span>
        </NavLink>
        {/*<div onClick={() => setOpen(true)} style={{ cursor: 'pointer' }}>*/}
        {/*  <span style={{ display: 'inline-block', verticalAlign: 'middle' }}>*/}
        {/*    <div style={{ fontWeight: 'bold' }}>Feedback</div>*/}
        {/*    <div>Send us your feedback and suggestions</div>*/}
        {/*  </span>*/}
        {/*</div>*/}
      </ArrowLinkList>
      {open ? (
        <Modal title={'test'} onRequestClose={() => setOpen(false)}>
          test
        </Modal>
      ) : null}
    </WhitePanel>
  );
}

const TeacherHomePage = withSizes(({ width }) => ({ fullsize: width > 1100 }))(({ fullsize }) => {
  useTitle('Dashboard');

  const placeholder1 = <MySessions />;
  const teacherHomePageResources = (
    <WhitePanel className={css.resourcesContainer}>
      <div className={css.panelTitle}>
        Resources
        <ResourcesSearch />
      </div>
      <div className={css.teacherHomePageResourcesInner}>
        <div className={css.actionButtons}>
          <ActionButtonArrow to="/resources/shop" className={css.resourcesButton}>
            Resource shop
          </ActionButtonArrow>
          <ActionButtonArrow to="/resources/owned" className={css.resourcesButton}>
            My school's resources
          </ActionButtonArrow>
        </div>
        <div className={css.innerBlock}>
          <h2>Marking resources </h2>
          <ArrowLinkList className={css.list}>
            <NavLink to="/resources/mark-packs">Download mark packs</NavLink>
          </ArrowLinkList>
        </div>
        <RecentDownloads />
      </div>
    </WhitePanel>
  );
  const support = <SupportLinks />;
  return (
    <LoadDataCache>
      <TeacherPageBody title={`Teacher Dashboard`}>
        {fullsize ? (
          <div className={css.contentBlocksContainer}>
            <div className={css.stickyLeftSection}>
              {placeholder1}
              {support}
            </div>
            <div className={css.scrolledRightSection}>
              {teacherHomePageResources}
              <TeacherHomePageNotifications />
              <TeacherHomePageArticles />
            </div>
          </div>
        ) : (
          <div className={css.contentBlocksContainer}>
            {placeholder1}
            {teacherHomePageResources}
            <div className={css.condensedRow}>
              {support}
              <TeacherHomePageNotifications />
            </div>

            <TeacherHomePageArticles />
          </div>
        )}
        <FloatingAd />
      </TeacherPageBody>
    </LoadDataCache>
  );
});
const FloatingAd = () => {
  const [closed, setClosed] = useState(false);
  if (closed) return <></>;
  return (
    <WhitePanel className={css.floatingAdContainer}>
      <div className={css.floatingAdContent}>
        <div className={css.floatingAdIconContainer}>
          <FontAwesomeIcon icon={faBullhorn} />
        </div>
        <div className={css.floatingText}>
          Are you interested in a rewarding and exciting professional development opportunity to
          earn a complementary income?&nbsp;
          <a className={css.textLink} href="https://mightyminds.com.au/employment" target="_blank">
            Find out more
          </a>
        </div>
      </div>
      <div className={css.closeFloatingAdContainer} onClick={() => setClosed(true)}>
        <span>×</span>
      </div>
    </WhitePanel>
  );
};
const TeacherHomePageArticles = () => {
  const articles = useLoadData('Articles');
  return (
    <WhitePanel className={`${css.articlesContainer} articlesContainer`}>
      <div className={css.panelTitle}>Mighty Minds News</div>
      {articles === undefined ? (
        <div className={css.loading}>
          <FontAwesomeIcon className={'fa-spin'} icon={faSpinner} />
        </div>
      ) : (
        <>
          {' '}
          {articles.length === 0 ? 'No news' : null}
          {articles.map(({ Title, ID, Content }) => (
            <div key={ID} className={css.articleContainer}>
              <div className={css.articleContentSection}>
                <div className={css.articleTitle}>
                  <h2>{Title}</h2>
                </div>
                <div dangerouslySetInnerHTML={{ __html: Content }} />
              </div>
              {/*<div className={css.articleSideSection} />*/}
            </div>
          ))}
        </>
      )}
    </WhitePanel>
  );
};

const RecentDownloads = () => {
  const downloads = useLoadData('RecentDownloads');
  return downloads === undefined ? (
    <div className={css.loading}>
      <FontAwesomeIcon className={'fa-spin'} icon={faSpinner} />
    </div>
  ) : (
    <div className={css.innerBlock}>
      <h2>My recent downloads</h2>
      {downloads.length > 0 ? (
        <ArrowLinkList className={`${css.list} ${css.recentDownloadsList}`}>
          {downloads
            .slice(0, 4)
            .map(({ ResourceTitle, ResourceImage, Link, DownloadDate }, index) => (
              <a key={`_${index}`} href={Link} title={DownloadDate}>
                <div className={css.resourceTitle}>{ResourceTitle}</div>
                <div className={css.downloadDate}>
                  {format(ssToJavascriptDate(DownloadDate), 'd MMM')}
                </div>
              </a>
            ))}
        </ArrowLinkList>
      ) : (
        <div style={{ color: secondaryTextColour, fontWeight: '600' }}>
          You have no recent downloads.
        </div>
      )}
    </div>
  );
};

function SingleNotification({
  notification: { Link, ResourceImage, Title, Date, Content, ResourceTitle, FromTeacher },
}) {
  const jsDate = ssToJavascriptDate(Date);
  return (
    <div className={css.notificationContainer}>
      <div className={css.notificationInner}>
        {ResourceTitle ? (
          <div className={css.notificationIconResource}>
            <FontAwesomeIcon icon={faExclamationCircle} />
          </div>
        ) : FromTeacher ? (
          <TeacherInitials user={FromTeacher} className={css.notificationIconUser} />
        ) : (
          <div></div>
        )}

        <div style={{ width: '100%', fontSize: '14px' }}>
          <div className={css.notificationTitle}>
            <h2>
              {ResourceTitle ? null : <FontAwesomeIcon icon={faCommentDots} />} {Title}
            </h2>

            <div className={css.sentTime} title={format(jsDate, 'h:mm a')}>
              {format(jsDate, 'd MMM')}
            </div>
          </div>
          <div dangerouslySetInnerHTML={{ __html: Content }} />
          {ResourceTitle ? (
            <a className={'button black hollow small'} href={Link}>
              More info
            </a>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export function Notifications({ style, noFooter, noTitle, className, onClickViewAll }) {
  const notifications = useLoadData('Notifications');

  return (
    <div className={classnames(css.notifications, className)} style={style}>
      {noTitle ? null : (
        <div className={css.panelTitle}>
          Notifications
          <NavLink to="/notifications" className={css.newMessageButton}>
            <FontAwesomeIcon icon={faPlus} />
            <div className={css.revealText}>
              <span>New message</span>
            </div>
          </NavLink>
        </div>
      )}
      <div className={css.notificationsContainerInner}>
        {notifications === undefined ? (
          <div className={css.loading}>
            <FontAwesomeIcon className={'fa-spin'} icon={faSpinner} />
          </div>
        ) : (
          <>
            {notifications.length === 0 ? 'no notifications' : null}
            {notifications.map((notification) => (
              <SingleNotification key={notification.ID} notification={notification} />
            ))}
          </>
        )}
      </div>

      {noFooter ? null : (
        <div className={css.notificationsFooter}>
          <NavLink to={'/notifications'} onNavigate={onClickViewAll}>
            View all notifications
          </NavLink>
        </div>
      )}
    </div>
  );
}

export function TeacherHomePageNotifications() {
  return (
    <FlexWhitePanel className={css.notificationsContainer}>
      <Notifications />
    </FlexWhitePanel>
  );
}

export default TeacherHomePage;
