/** @format */

import React, { Suspense } from 'react';
import { __RouterContext, matchPath, useLocation, useRouteMatch } from 'react-router-dom';
import { animated, useTransition } from 'react-spring';
import { fast } from '../lib/animationConfigs';

/**
 * This performs the same behaviour as React Router's Switch component, but will animate the
 * mounting and unmounting of the child routes. It was necessary to reimplement Switch's behaviour
 * because we need to animate when the matching route changes, which isn't necessarily when the
 * location changes, and thus we need to know the matching route inside this component, not just
 * inside React Router's Switch.
 *
 */
export function AnimatedSwitch({ children }) {
  return <ActuallyAnimatedSwitch children={children} />;
}

function ActuallyAnimatedSwitch({ children }) {
  const location = useLocation();
  const parentMatch = useRouteMatch();
  const transitions = useTransition(
    location,
    location =>
      React.Children.toArray(children).findIndex(child => {
        if (React.isValidElement(child)) {
          const path = child.props.path || child.props.from;
          const match = path ? matchPath(location.pathname, { ...child.props, path }) : parentMatch;
          if (match) return true;
        }
      }),
    {
      enter: { opacity: 1 },
      leave: { opacity: 0 },
      from: { opacity: 0 },
      config: fast,
      unique: true
    }
  );
  return transitions.map(({ item: location, key, props }) =>
    key == -1 ? null : (
      <animated.div key={key} style={props}>
        <Suspense fallback={''}>
          {React.cloneElement(React.Children.toArray(children)[key], { location })}
        </Suspense>
      </animated.div>
    )
  );
}
