/** @format */

import React, { useState, useRef, useEffect, useCallback } from 'react';
import Modal from 'react-modal';
import css from './GetStarted.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { mmArrowRightFat } from '../../icon/mmArrowRightFat';
import { useDefaultingReducer } from '../../hooks/useDefaultingReducer';
import NavLink from './NavLink';
import { mmFlag } from '../../icon/mmFlag';
import modalCss from '../Modal.scss';
import { useDispatch, useSelector } from 'react-redux';
import { LargeActionButtonOutlined } from '../buttons';
import { activityListingLinks, createActivityLinks } from '../../lib/link-data';
import { useTeacherContext } from '../../js/teacher-pages/context';

function createSessionTypeButtons(map) {
  return ['Cognitive Skills', 'Fundamental Skills', 'NAPLAN', 'Writing Tasks'].map((label) => ({
    label,
    link: map[label],
  }));
}

const getStartedData = {
  question: 'What would you like to do today?',
  sections: [
    {
      title: 'Sessions',
      buttons: [
        {
          label: (
            <>
              Create <span className={css.emphasise}>online</span> session
            </>
          ),
          subquestion: {
            question: 'What type of session?',
            sections: [
              {
                buttons: createSessionTypeButtons(createActivityLinks),
              },
            ],
          },
        },
        {
          label: (
            <>
              Organise <span className={css.emphasise}>offline</span> exam
            </>
          ),
          subquestion: {
            question: 'What type of exam?',
            sections: [
              {
                buttons: [
                  {
                    label: 'NAPLAN',
                    link: '/resources/mark-packs?t=naplan',
                  },
                  {
                    label: 'Diagnostic Test',
                    link: '/resources/mark-packs?t=diagnostic',
                  },
                ],
              },
            ],
          },
        },
        {
          label: 'Manage sessions',
          subquestion: {
            question: 'What type of session?',
            sections: [
              {
                buttons: createSessionTypeButtons(activityListingLinks),
              },
            ],
          },
        },
      ],
    },
    {
      title: 'Tracking',
      buttons: [
        {
          label: 'Track student data',
          subquestion: {
            question: 'What type of data would you like to track?',
            sections: [
              {
                buttons: [
                  {
                    label: 'Individual student data',
                    link: '/track/students',
                  },
                  {
                    label: 'Class and cohort data',
                    link: '/track/class',
                  },
                  {
                    label: 'Weekly tasks',
                    link: '/track/weekly',
                  },
                  {
                    label: 'Portal usage',
                    link: '/track/usage',
                  },
                ],
              },
            ],
          },
        },
      ],
    },
    {
      title: 'Resources',
      buttons: [
        {
          label: 'Download mark packs',
          link: '/resources/mark-packs',
        },
        {
          label: 'Download resources',
          subquestion: {
            question: "Does your school already own the resource you're looking for?",
            sections: [
              {
                buttons: [
                  { label: 'Download owned resource', link: '/resources/owned' },
                  { label: 'Purchase new resource', link: '/resources/shop' },
                ],
              },
            ],
          },
        },
        {
          label: 'View workshop material',
          link: '/resources/workshops',
        },
      ],
    },
    // NOTE: removed as requested - https://mightyminds.atlassian.net/jira/software/projects/CPI/boards/3?selectedIssue=CPI-25
    // {
    //   title: 'Courses',
    //   requirement: ({ schoolCourses }) => schoolCourses,
    //   buttons: [
    //     {
    //       label: 'Manage Links to Life',
    //       link: '/activities/links-to-life'
    //     }
    //   ]
    // }
  ],
};

// eslint-disable-next-line default-param-last
function reducer(state = [getStartedData], action) {
  if (action.type === 'push') {
    return [action.payload, ...state];
  }
  if (action.type === 'pop') {
    return state.slice(1);
  }
  return state;
}

export function GetStarted({ onClose }) {
  const [stack, dispatch] = useDefaultingReducer(reducer);
  const [current, isStacked] = stack;
  const [height, setHeight] = useState();
  const containerRef = useRef();

  const context = useTeacherContext();

  useEffect(() => {
    if (!height && containerRef.current) {
      setHeight(containerRef.current.clientHeight);
    }
  }, [height]);

  return (
    <div className={css.getStartedContainer} style={{ minHeight: height }} ref={containerRef}>
      <div className={css.getStarted}>
        <div className={css.headerSection}>
          <span className={css.absoluteCloseIcon} onClick={onClose}>
            ×
          </span>
          {isStacked ? (
            <div
              className={`${css.circularElement} ${css.goBack}`}
              onClick={() => dispatch({ type: 'pop' })}
            >
              <FontAwesomeIcon icon={mmArrowRightFat} />
            </div>
          ) : (
            <div className={css.circularElement}>
              <FontAwesomeIcon icon={mmFlag} />
            </div>
          )}
        </div>
        <div className={css.mainSection}>
          <div className={css.directionsContainer}>
            <h3>{current.question}</h3>
            <span className={css.directionSubText}>Choose from the options below</span>
          </div>

          <div className={css.optionsContainer}>
            {current.sections.map(({ title, buttons, requirement }, index) =>
              !requirement || requirement(context) ? (
                <React.Fragment key={title ?? index}>
                  <div className={css.optionsGroup}>
                    {title ? <h6 className={css.optionsGroupHeader}>{title}</h6> : null}
                    <ul>
                      {buttons.map(({ label, subquestion, link }, i) => (
                        <li
                          key={`_${i}`}
                          onClick={
                            subquestion
                              ? () => dispatch({ type: 'push', payload: subquestion })
                              : null
                          }
                        >
                          {link ? (
                            <NavLink to={link} onNavigate={onClose}>
                              {label}
                            </NavLink>
                          ) : (
                            label
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                </React.Fragment>
              ) : null
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function getGetStartedOpen(state) {
  return state.getStarted.open;
}

export const GetStartedButton = React.memo(({ collapsed }) => {
  const dispatch = useDispatch();
  const open = useCallback(() => dispatch({ type: 'GET_STARTED_OPEN', open: true }), [dispatch]);

  return !collapsed ? (
    <div className={css.buttonMargin}>
      <LargeActionButtonOutlined onClick={open}>
        Get started <FontAwesomeIcon icon={mmArrowRightFat} className={css.arrowIcon} />
      </LargeActionButtonOutlined>
    </div>
  ) : (
    <div className={css.circle} onClick={open}>
      <FontAwesomeIcon icon={mmFlag} />
    </div>
  );
});

export function GetStartedModal() {
  const open = useSelector(getGetStartedOpen);
  const dispatch = useDispatch();
  const close = useCallback(() => dispatch({ type: 'GET_STARTED_OPEN', open: false }), [dispatch]);
  return open ? (
    <Modal
      isOpen
      overlayClassName={modalCss.flexOverlay}
      className={css.modal}
      onRequestClose={close}
    >
      <GetStarted onClose={close} />
    </Modal>
  ) : null;
}
