/** @format */

import { combineReducers } from 'redux';
import user from './user';
import getStarted from './getStarted';
import graphQLValidation from './graphQLValidation';
import message from './message';

const reducer = combineReducers({
  user,
  getStarted,
  graphQLValidation,
  message
});

export default reducer;
