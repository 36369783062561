/** @format */
import React from 'react';
import css from './TeacherNavigationFooter.scss';
import { Button } from './buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';

export function TeacherNavigationFooter({ collapsed }) {
  return !collapsed ? (
    <div className={css.teacherNavigationFooter}>
      <Button hollow large lowAlert as="a" href="/LogOut">
        Log out
      </Button>

      <div className={css.linksContainer}>
        <a target="_blank" href="http://mightyminds.com.au/privacy-policy">
          Privacy Policy
        </a>
        <a target="_blank" href="http://mightyminds.com.au/terms-and-conditions/">
          Terms and Conditions
        </a>
        <a target="_blank" href="./portal-licence-agreement/">
          Licence Agreement
        </a>

        <div>© Mighty Minds</div>
      </div>
    </div>
  ) : (
    <div className={css.teacherNavigationFooter}>
      <a href="/LogOut">
        <FontAwesomeIcon icon={faSignOutAlt} className={css.collapsedLogoutButton} />
      </a>
    </div>
  );
}
