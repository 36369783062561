/** @format */

export function notificationPopup() {
  fetch('api/notifications/get')
    .then((r) => r.json())
    .then(({ notification }) => {
      if (notification) {
        $('#reveal-popup-content').html(notification.content);
        $('#reveal-popup').foundation();
        $('#reveal-popup').foundation('open');

        const onClose = () => {
          fetch(`api/notifications/read/${notification.id}`);
          $('#reveal-popup').off('closed.zf.reveal', onClose);
        };

        $('#reveal-popup').on('closed.zf.reveal', onClose);
      }
    });
}
