/** @format */

import { style } from '../lib/styling';
import React from 'react';
import NavLink from './TeacherSPA/NavLink';
import classnames from 'classnames';
import css from './buttons.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { mmArrowRightFat } from '../icon/mmArrowRightFat';

export const LargeActionButtonOutlined = style('div', css.largeActionButtonOutlined);

export const Button = React.forwardRef(function Button({
  alert,
  white,
  hollow,
  margin,
  disabled,
  secondary,
  lowAlert,
  children,
  large,
  className,
  as: Component = 'div',
  ...props
}, ref) {
  return (
    <Component
      ref={ref}
      className={classnames({ button: true, alert, white, hollow, margin, disabled, secondary, large, lowAlert }, className)}
      children={children}
      {...props}
    />
  );
});

export const ActionButtonArrow = React.forwardRef(
  ({ small, shadowed, children, style, className, white, ...rest }, ref) => {
    const Component = rest.to ? NavLink : 'div';
    return (
      <Component
        ref={ref}
        className={classnames(css.actionButtonArrow, className, {
          white,
          large: !small,
          [css.shadowed]: shadowed
        })}
        style={style}
        {...rest}
      >
        {children} <FontAwesomeIcon icon={white ? faChevronDown : mmArrowRightFat} />
      </Component>
    );
  }
);
