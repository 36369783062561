jQuery(document).ready(($) => {

    // When there's a footer, set the page content's height so that the footer
    // ends up at least at the bottom of the page
    if ($('footer').length > 0) {
        (function () {
            if ($('.StudentPage').length > 0) {
                // This was 308 before I changed this to work with teacher pages
                // as well. I just incremented it up until the pages weren't
                // scrolling anymore.
                function AdjustMinHeight () {
                    const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
                    const AdjustedHeight = h - 173;
                    $('.main-layout').css('min-height', AdjustedHeight);
                }
                $(window).resize(AdjustMinHeight);
                AdjustMinHeight();
            }
        }());
    }

    if ($('#Form_processWorkshopBookingForm_ProposedWorkshopDate').length > 0) {
        $.datetimepicker.setLocale('en');
        $('#Form_processWorkshopBookingForm_ProposedWorkshopDate').datetimepicker({
            mask: false,
            vformat: 'unixtime',
            step: 30,
            minDate: 0,
            startDate: new Date()
        });
    }

    $('a#pipExplanationButton').click((e) => {
        e.preventDefault();
        $('#pipExplanation').slideToggle(400);
    });

    $('a#pipExplanationHide').click((e) => {
        e.preventDefault();
        $('#pipExplanation').hide(400);
    });
});
