/** @format */

import { lazy } from 'react';

const CognitiveVerbPage = lazy(() =>
  import(
    /* webpackChunkName: "CognitiveVerbPage" */ '../../pages/CognitiveVerbs/CognitiveVerbRoute'
  )
);
const CognitiveVerbCreatePage = lazy(() =>
  import(
    /* webpackChunkName: "CognitiveVerbPage" */ '../../pages/CognitiveVerbs/CognitiveVerbCreateRoute'
  )
);
const NAPLANPage = lazy(() =>
  import(/* webpackChunkName: "SessionPages" */ '../SessionPages/NAPLANPage')
);
const FSPage = lazy(() => import(/* webpackChunkName: "SessionPages" */ '../SessionPages/FSPage'));
const DiagnosticTestsPage = lazy(() =>
  import(/* webpackChunkName: "SessionPages" */ '../SessionPages/DiagnosticTestsPage')
);
const WritingTasksPage = lazy(() =>
  import(/* webpackChunkName: "SessionPages" */ '../SessionPages/WritingTasksPage')
);
const QCSPage = lazy(() =>
  import(/* webpackChunkName: "DashboardApp" */ '../SessionPages/QCSPage')
);
const PracticeTrackingPage = lazy(() =>
  import(
    /* webpackChunkName: "PracticeTrackingApp" */ '../../pages/PracticeTracking/PracticeTrackingRoute'
  )
);
const DashboardPage = lazy(() =>
  import(/* webpackChunkName: "DashboardApp" */ '../../pages/Dashboard/DashboardRoute')
);
const SPTrackPage = lazy(() =>
  import(/* webpackChunkName: "SPTrackApp" */ '../../pages/SPTrackPageRoute')
);
const TeacherNotificationsPage = lazy(() =>
  import(
    /* webpackChunkName: "TeacherNotificationsPage" */ '../../pages/TeacherNotificationsPage/TeacherNotificationsRoute'
  )
);
const TeacherTheoryPage = lazy(() =>
  import(
    /* webpackChunkName: "TeacherTheoryPage" */ '../../pages/StudentTheoryPage/TeacherTheoryRoute'
  )
);
const FAQPage = lazy(() =>
  import(/* webpackChunkName: "HelpRoute" */ '../../pages/Support/FAQRoute')
);

const HelpPage = lazy(() =>
  import(/* webpackChunkName: "HelpRoute" */ '../../pages/Support/HelpRoute')
);

const ManageStudentsPage = lazy(() =>
  import(
    /* webpackChunkName: "SchoolManagement" */ '../../pages/ManageStudents/ManageStudentsRoute'
  )
);
const ManageGroupsPage = lazy(() =>
  import(
    /* webpackChunkName: "SchoolManagement" */ '../../pages/ManageGroups/ManageGroupsRoute'
  )
);
const CVSDTrackRoute = lazy(() =>
  import(
    /* webpackChunkName: "CVSDTrack" */ '../../pages/CVSDTrack/CVSDTrackRoute'
  )
);
export {
  CognitiveVerbPage,
  CognitiveVerbCreatePage,
  NAPLANPage,
  FSPage,
  DiagnosticTestsPage,
  WritingTasksPage,
  QCSPage,
  PracticeTrackingPage,
  DashboardPage,
  TeacherTheoryPage,
  FAQPage,
  HelpPage,
  TeacherNotificationsPage,
  ManageStudentsPage,
  ManageGroupsPage,
  SPTrackPage,
  CVSDTrackRoute
};
