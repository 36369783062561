/** @format */

import React, { useRef, useState } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import { Manager, Reference } from 'react-popper';
import classnames from 'classnames';
import css from './TeacherHeader.scss';
import { TeacherPopper } from '../TeacherPopper';

export function HeaderDropdown({
  children,
  dropdown,
  className,
  isOpen: isOpenProp,
  onOpen: setOpenProp
}) {
  const [isOpenState, setOpenState] = useState(false);
  const managed = isOpenProp !== undefined;
  const isOpen = managed ? isOpenProp : isOpenState;
  const setOpen = managed ? setOpenProp : setOpenState;
  const sectionRef = useRef();
  useOnClickOutside(sectionRef, () => setOpen(false));
  return (
    <Manager>
      <Reference innerRef={e => (sectionRef.current = e)}>
        {({ ref }) => (
          <div
            ref={ref}
            className={classnames(css.headerSegment, className, isOpen ? css.opened : null)}
            onClick={() => setOpen(!isOpen)}
          >
            {children}
            {isOpen ? (
              <TeacherPopper
                placement="bottom"
                modifiers={{
                  offset: {
                    enabled: true,
                    offset: '0, 6'
                  }
                }}
              >
                {dropdown}
              </TeacherPopper>
            ) : null}
          </div>
        )}
      </Reference>
    </Manager>
  );
}
