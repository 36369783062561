/** @format */

import { useSelector } from 'react-redux';
import Modal from 'react-modal';
import css from '../GraphQLValidationNotification.scss';
import React from 'react';

const getGraphQLValidation = ({ graphQLValidation }) => graphQLValidation;

export default function GraphQLValidationNotification() {
  const error = useSelector(getGraphQLValidation);
  if (error) {
    return (
      <Modal
        overlayClassName={css.overlay}
        className={`${css.modal}`}
        style={{
          position: 'absolute'
        }}
        isOpen={true}
      >
        <div style={{ padding: 10, maxWidth: 600, textAlign: 'center' }}>
          It looks like you might have been logged out. Please refresh the page and try again. If
          this persists, please contact Mighty Minds.
        </div>
      </Modal>
    );
  }
  return null;
}
