/** @format */

import React from 'react';
import TeacherHeader from './TeacherHeader';

import TeacherNavigation from './TeacherNavigation';
import { navBreakpointSmall } from '../../css/teacher-spa.scss';
import useSizes from '../../hooks/useSizes';
import TeacherHeaderMobile from './TeacherHeaderMobile';
import TeacherNavigationMobile from './TeacherNagivationMobile';

const small = parseInt(navBreakpointSmall.slice(0, -2));

const user = {
  Name: 'Mason Dechaineux',
  IsSchoolAdmin: true
};

function TeacherMenus() {
  const mobile = useSizes(({ width }) => width <= small);

  if (mobile) {
    return (
      <>
        <TeacherHeaderMobile />
        <TeacherNavigationMobile />
      </>
    );
  }
  return (
    <>
      <TeacherHeader />
      <TeacherNavigation />
    </>
  );
}

export default TeacherMenus;
