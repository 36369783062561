/** @format */

class Queue {
  constructor({ interval, suspendDelay, intervalLimit, endpoint, maxFailed }) {
    this.queue = [];
    this.initialInterval = interval ?? 2000;
    this.interval = this.initialInterval;
    this.intervalLimit = intervalLimit ?? 20000; // 20 SECONDS
    this.suspendDelay = suspendDelay ?? 1000;
    this.endpoint = endpoint;
    this.isSending = false;
    this.isSuspended = false;
    this.nodeInterval = setInterval(() => this._send(), this.interval);
    this.failedAttempts = 0;
    this.maxFailed = maxFailed || 5;
  }

  queueMsg(msg) {
    if (!msg?.message) {
      return;
    }
    this.queue.push(msg);
  }

  _onFail() {
    this.isSuspended = true;
    this.failedAttempts += 1;
    let nextInterval = this.interval * 2;

    if (nextInterval > this.intervalLimit) {
      nextInterval = this.interval;
    }

    this.interval = nextInterval;

    this.nodeInterval = clearInterval(this.nodeInterval);

    if (this.failedAttempts >= this.maxFailed) {
      // Stop trying to send data to backend
      return;
    }

    setTimeout(() => {
      this.isSuspended = false;
      this._resetInterval();
    }, this.suspendDelay);
  }

  _resetInterval() {
    if (this.nodeInterval !== undefined) {
      this.nodeInterval = clearInterval(this.nodeInterval);
    }
    this.nodeInterval = setInterval(() => this._send(), this.interval);
  }

  _onSuccess() {
    this.failedAttempts = 0;
    if (this.interval > this.initialInterval) {
      this.interval = this.initialInterval;
      this._resetInterval();
    }
  }

  async _send() {
    if (this.isSending || this.isSuspended) {
      return;
    }
    this.isSending = true;
    const send = [...this.queue];
    this.queue = [];
    try {
      if (send.length !== 0) {
        const request = await fetch(this.endpoint, {
          method: 'POST',
          body: JSON.stringify({ logs: send }),
        });
        if (!request.ok || request.status !== 200) {
          throw Error(request.statusText);
        }
        this._onSuccess();
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(send);
      this.queue.unshift(...send);
      this._onFail();
    } finally {
      this.isSending = false;
    }
  }
}

export default Queue;
