/** @format */

import React from 'react';
import { style } from '../../lib/styling';
import css from './TeacherPageBody.scss';
import classnames from 'classnames';
import { FlexSeparator } from '../FlexSeparator';

const TeacherPageTitle = style('div', css.title);

export function TeacherPageBody({ children, title, actions, className, style }) {
  return (
    <div className="main main-layout main-layout--teacher" role="main">
      <div className={classnames('teacher-content', className)} style={style}>
        <div className={actions ? classnames(css.titleBar) : null}>
          {title ? <TeacherPageTitle>{title}</TeacherPageTitle> : null}
          {actions ? <div>{actions}</div> : null}
        </div>
        {children}
      </div>
    </div>
  );
}

export function TeacherPageFixedBody({
  children,
  title,
  actions,
  style,
  inlineActions = false,
  maxWidth = 1200,
}) {
  return (
    <div className={css.fixedContent}>
      <div className={css.capWidth}>
        <div className={css.centeredContent} style={{ maxWidth, height: '100%' }}>
          {title || actions ? (
            <div
              className={
                actions
                  ? classnames(css.titleBar, inlineActions && css.titleBarInlineButtons)
                  : null
              }
            >
              {title ? <TeacherPageTitle>{title}</TeacherPageTitle> : null}
              {actions && inlineActions ? <FlexSeparator /> : null}
              {actions ? (
                <div style={inlineActions ? { whiteSpace: 'nowrap' } : null}>{actions}</div>
              ) : null}
            </div>
          ) : null}
          <div className={css.mainContent} style={style}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
