import colourFromPercent from '../lib/colourFromPercent';
import escapeHTML from '../lib/escapeHTML';

(function ($, document) {
    $.fn.extend({
        /**
         * Replaces the contents with a loading animation.
         * @param colour 'colour' or 'white'
         * @param opts   See http://api.jquery.com/jQuery/#jQuery2
         * @return this
         */
        loadingOwl(colour, opts) {
            return this.each(function () {
                $(this).empty();
                $('<div></div>', opts)
                    .addClass('loading-animation')
                    .addClass(colour)
                    .appendTo(this)
                    .fadeIn('slow');
            });
        },

        /**
         * Selectize, but change the default behaviour so that you can type
         * immediately after clicking on a select that already has something
         * selected.
         * @param opts passed straight onto .selectize()
         */
        mmSelectize(opts) {
            return this.each(function () {
                $(this).selectize($.extend({
                    onDropdownClose($dropdown) {
                        $($dropdown).find('.selected').not('.active').removeClass('selected');
                    }
                }, opts));
                let saved = null;
                this.selectize.on('focus', function () {
                    saved = this.items[0];
                    this.removeItem(saved);
                });
                this.selectize.on('blur', function () {
                    if (this.items.length === 0) {
                        this.addItem(saved);
                    }
                });
            });
        }
    });

    $.mmLoadingOverlay = function (arg) {
        if (arg === 'close') {
            $.fancybox.hideLoading();
        } else {
            $.fancybox.showLoading();
        }
    };

    // Legacy. TODO: Can remove once the student profile page has been converted to react.
    $.mmColorFromPercent = colourFromPercent;
    $.mmColorizeBars = function () {
        $('.ColoredPercentageBar').each(function () {
            const p = $(this).data('percent');
            const c = colourFromPercent(p);
            $(this).css('background-color', c);
        });
    };

    /**
     * jQuery-confirm alert with sensible defaults for the portal, which doesn't
     * use bootstrap.
     * @param  opts Requires at least title and content properties.
     */
    $.mmAlert = function (opts) {
        return $.alert($.extend({
            draggable: false,
            containerFluid: false,
            useBootstrap: false,
            boxWidth: 'calc( 400px + 10% )',
            backgroundDismiss: true,
            animation: 'scale',
        }, opts));
    };

    $.mmConfirm = (title, content, action) =>
        new Promise((resolve, reject) =>
            $.mmAlert({
                title,
                content,
                buttons: {
                    cancel: {
                        btnClass: 'btn-redNo',
                        action: reject
                    },
                    yes: {
                        btnClass: 'btn-Yes',
                        action() {
                            action(this)?.then(resolve, reject);
                        }
                    }
                }
        }));

    $(() => {
        const mobile = (() => {
            let check = false;
            (function (a) {
                if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
            })(navigator.userAgent || navigator.vendor || window.opera);
            return check;
        })();

        if ($().selectize) {
            /*
            * Automatically initialise selectize with our modified behaviour on every
            * select element with the class 'selectize'.
            */
            $('select.selectize').mmSelectize();

            $('select.selectize-body').mmSelectize({dropdownParent: 'body'});

            $('select.selectize-default').selectize({

                onDropdownClose($dropdown) {
                    $($dropdown).find('.selected').not('.active').removeClass('selected');
                }
            });

            if (mobile) {
                $('.selectize').next().find('.selectize-input > input').prop('disabled', 'disabled');
            }
        }

        // back button
        $('.history-back-button').click(() => {
            parent.history.back();
        });
    });
}(jQuery, document));

