/** @format */

import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { convertToTimeZone } from 'date-fns-timezone';
import { useSnackbar } from 'notistack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const usePageVisibility = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsVisible(!document.hidden);
    };

    document.addEventListener('visibilitychange', handleVisibilityChange, false);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return isVisible;
};

const useMaintenanceBanner = (isVisible) => {
  const [showBanner, setShowBanner] = useState(false);
  const [minutesUntil, setMinutesUntil] = useState(null);

  const calculate = useCallback(() => {
    const now = new Date();
    const qldTime = convertToTimeZone(now, { timeZone: 'Australia/Brisbane' });
    const hour = qldTime.getHours();
    // Set maintenance window for 1 hour before 11PM QLD time
    if (hour === 22 || hour === 23) {
      const minutes = 60 - qldTime.getMinutes();
      setMinutesUntil(hour === 23 ? 0 : minutes);
      setShowBanner(true);
    } else {
      setShowBanner(false);
      setMinutesUntil(null);
    }
  }, []);

  useEffect(() => {
    let interval;

    if (isVisible) {
      calculate();
      interval = setInterval(calculate, 10000); // Check every 10 seconds
    }

    return () => clearInterval(interval);
  }, [calculate, isVisible]);

  return { showBanner, minutesUntil };
};

const NotificationMessage = forwardRef(({ initialMinutesUntil }, ref) => {
  const [minutesUntil, setMinutesUntil] = useState(() => initialMinutesUntil);
  useImperativeHandle(ref, () => ({
    setMinutesUntil: (minutesUntil) => setMinutesUntil(minutesUntil),
  }));

  return (
    <div>
      <div
        style={{
          textAlign: 'center',
          fontWeight: 'bold',
          fontSize: '20px',
          marginBottom: '10px',
        }}
      >
        Site Maintenance
      </div>
      <p style={{ marginBottom: 0, fontSize: '16px' }}>
        The portal will shut down for maintenance in{' '}
        <strong>
          {minutesUntil} {minutesUntil === 0 || minutesUntil > 1 ? 'minutes' : 'minute'}
        </strong>
        . Please ensure you save your work!
      </p>
    </div>
  );
});

function createBannerShowing(minutesUntil, key) {
  if (!minutesUntil || !key) {
    return { isShowing: false };
  }
  return { minutesUntil, key, isShowing: true };
}

function useBannerNotification() {
  const isVisible = usePageVisibility();
  const { showBanner, minutesUntil } = useMaintenanceBanner(isVisible);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dismissed = useRef(new Set());
  const bannerShowing = useRef(createBannerShowing());
  const activeBanner = useRef();

  const { shouldShow, dismiss, setShowing, reset } = useMemo(() => {
    const setDismissed = (minutesUntil) => {
      dismissed.current.add(minutesUntil);
    };

    const hasBeenDismissed = (minutesUntil) => dismissed.current.has(minutesUntil);

    const setShowing = (minutesUntil, key) => {
      bannerShowing.current = createBannerShowing(minutesUntil, key);
    };

    const reset = (currentMinutesUntil) => {
      if (!bannerShowing.current.isShowing) {
        return;
      }

      const { minutesUntil } = bannerShowing.current;

      if (
        (minutesUntil > 10 && currentMinutesUntil <= 10) ||
        (minutesUntil > 5 && currentMinutesUntil <= 5)
      ) {
        dismiss();
      }
    };

    const shouldShow = (minutesUntil) =>
      !bannerShowing.current.isShowing &&
      (dismissed.current.size < 1 || [60, 30, 10, 5, 1].includes(minutesUntil)) &&
      !hasBeenDismissed(minutesUntil);

    const dismiss = () => {
      const { key, minutesUntil, isShowing } = bannerShowing.current;

      if (!isShowing) {
        return;
      }

      closeSnackbar(key);
      setDismissed(minutesUntil);
      setShowing();
    };

    return { shouldShow, dismiss, setShowing, reset };
  }, [closeSnackbar]);

  useEffect(() => {
    activeBanner.current?.setMinutesUntil?.(minutesUntil);
  }, [minutesUntil]);

  useEffect(() => {
    function getVariant() {
      switch (minutesUntil) {
        case 10:
          return 'warning';
        case 5:
        case 1:
          return 'error';
        default:
          return 'info';
      }
    }

    reset(minutesUntil);

    if (!showBanner) {
      dismiss();
    }

    if (showBanner && isVisible && shouldShow(minutesUntil)) {
      const key = enqueueSnackbar(
        <NotificationMessage initialMinutesUntil={minutesUntil} ref={activeBanner} />,
        {
          persist: true,
          variant: getVariant(),
          action: (key) => (
            <button
              type="button"
              onClick={() => dismiss(key)}
              style={{
                position: 'absolute',
                top: 10,
                right: 10,
                color: 'white',
                cursor: 'pointer',
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          ),
        }
      );

      setShowing(minutesUntil, key);
    }
  }, [
    enqueueSnackbar,
    isVisible,
    showBanner,
    minutesUntil,
    closeSnackbar,
    shouldShow,
    setShowing,
    dismiss,
    reset,
  ]);
}

export default useBannerNotification;
