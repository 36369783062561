/** @format */
import React, { createContext, useContext, useEffect } from 'react';
import { useSnackbar } from 'notistack';

const Context = createContext();

export function useTeacherContext() {
  return useContext(Context);
}

export function useSchoolID() {
  const ctx = useContext(Context);

  return ctx?.user?.SchoolID;
}

export const TeacherContextProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (window.mm.notification) {
      enqueueSnackbar(window.mm.notification.message, { variant: window.mm.notification.type });
    }
  }, []);

  return <Context.Provider value={window.mm}>{children}</Context.Provider>;
};
