import React, { useCallback, useState } from 'react';
import useNavigationState from './useNavigationState';
import { slide as Menu } from 'react-burger-menu';
import css from './TeacherMenus.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import SiteTree from './SiteTree';
import { withRouter } from 'react-router-dom';

function TeacherNavigationMobile({ location }) {
  const [collapsed, setCollapsed] = useState(true);
  const [open, setOpen] = useNavigationState({ location, onPageChange: () => setCollapsed(true) });
  const toggle = useCallback(
    title => {
      setOpen(open => (open[title] ? {} : { [title]: true }));
    },
    [setOpen]
  );
  const handleOpenChange = useCallback(({ isOpen }) => setCollapsed(!isOpen), [setCollapsed]);
  return (
    <Menu
      isOpen={!collapsed}
      onStateChange={handleOpenChange}
      burgerButtonClassName={css.burgerButton}
      overlayClassName={css.burgerOverlay}
      menuClassName={css.burgerMenu}
      className={css.burgerWrap}
      crossClassName={css.burgerCross}
      customBurgerIcon={<FontAwesomeIcon icon={faBars}/>}
    >
      <div style={{ paddingTop: 30 }}>
        <SiteTree open={open} onClick={toggle}/>
      </div>
    </Menu>
  );
}

export default withRouter(TeacherNavigationMobile);