/** @format */

import React from 'react';
import { TeacherPageFixedBody } from './TeacherPageBody';
import css from  './TeacherErrorBoundary.scss';
import { FlexWhitePanel } from '../ActivityHomepage/Panels';
import { cold, setConfig } from 'react-hot-loader';
import { withRouter } from 'react-router-dom';
import { RedBoxError } from 'redbox-react';
import { trafficRed } from 'Globals';
import {Button} from "../buttons";
import {faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons/faExclamationCircle";
import {faSadCry} from "@fortawesome/free-regular-svg-icons/faSadCry";
import NavLink from "./NavLink";

export class TeacherErrorBoundary extends React.Component {
  state = {
    error: null,
    lastPathname: null
  };

  static getDerivedStateFromError(error) {
    console.error(error);
    return { error };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.location.pathname !== prevState.lastPathname) {
      return { error: null, lastPathname: nextProps.location.pathname };
    }
    return {};
  }

  renderError() {
    if (__DEV__) {
      return (
        <TeacherPageFixedBody>
          <RedBoxError
            error={this.state.error}
            style={{
              redbox: {
                backgroundColor: trafficRed,
                width: '100%',
                color: 'white',
                fontSize: 16,
                borderRadius: 8,
                padding: 20
              }
            }}
          />
        </TeacherPageFixedBody>
      );
    }

    return (
      <TeacherPageFixedBody>
          <div className={css.pageErrorContainer}>
            <FontAwesomeIcon icon={faSadCry} style={{ marginBottom: '15px', fontSize: '30px' }} />
            <h1 style={{marginBottom: '10px'}}>Oops! Looks like something went wrong.</h1>

           {/*<h1 style={{marginBottom: '10px'}}>Oops! The page you were looking for doesn't exist.</h1>
            <p>You may have mistyped the address or the page may have been moved.</p>*/}

            <NavLink className={'button margin'} to="/">Go to dashboard</NavLink>
            <div className={css.backgroundCircleElement1}> </div>
            <div className={css.backgroundCircleElement2}> </div>
            <div className={css.backgroundCircleElement3}> </div>
          </div>
      </TeacherPageFixedBody>
    );
  }

  render() {
    return !this.state.error ? this.props.children : this.renderError();
  }
}

if (module.hot) {
  TeacherErrorBoundary.prototype.componentDidMount = function() {
    setConfig({
      onComponentRegister: () => this.setState({ error: null })
    });
  };
  TeacherErrorBoundary.prototype.componentWillUnmount = function() {
    setConfig({
      onComponentRegister: null
    });
  };
  cold(TeacherErrorBoundary);
}
export default withRouter(TeacherErrorBoundary);
