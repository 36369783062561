/** @format */

import React from 'react';

const onPath =
  'M6.7 18.2c0 1.3 1.1 2.4 2.4 2.4s2.4-1.1 2.4-2.4H6.7zM17 15c-.7-.8-2-1.9-2-5.7v-.4c-.5.2-1.1.3-1.8.3v.1c0 3.1.9 4.8 1.6 5.9H3.3c.8-1 1.6-2.7 1.6-5.9 0-1.9 1.3-3.5 3.1-4-.1-.4-.1-.9-.1-1.4v-.3c-2.7.5-4.7 2.8-4.7 5.7 0 3.8-1.3 4.9-2 5.7-.2.2-.3.5-.3.8-.1.6.4 1.2 1.1 1.2h14.2c.7 0 1.2-.6 1.2-1.2-.1-.3-.2-.6-.4-.8z';

const offPath =
  'M17,15c-0.7-0.8-2-1.9-2-5.7c0-2.9-2-5.2-4.7-5.7V2.8c0-0.7-0.5-1.2-1.2-1.2S7.9,2.2,7.9,2.8v0.8C5.2,4.1,3.2,6.4,3.2,9.3 c0,3.8-1.3,4.9-2,5.7c-0.2,0.2-0.3,0.5-0.3,0.8C0.8,16.4,1.3,17,2,17h14.2c0.7,0,1.2-0.6,1.2-1.2C17.3,15.5,17.2,15.2,17,15L17,15z M3.3,15.2c0.8-1,1.6-2.7,1.6-5.9c0,0,0,0,0,0c0-2.3,1.8-4.1,4.1-4.1s4.1,1.8,4.1,4.1c0,0,0,0,0,0c0,3.1,0.9,4.8,1.6,5.9H3.3z M9.1,20.5c1.3,0,2.4-1.1,2.4-2.4H6.7C6.7,19.5,7.8,20.5,9.1,20.5z';

export function NotificationBell({ on, width }) {
  return (
    <svg style={{ width: width, height: width }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.1 20.9">
      {on ? (
        <>
          <circle
            className="animated pulse"
            style={{ animationIterationCount: 3, transformOrigin: 'top right' }}
            fill="#1fff00"
            id="Ellipse_16_1_"
            cx="13.1"
            cy="3.9"
            r="3.6"
          />
          <path fill="white" d={onPath} />
        </>
      ) : (
        <path d={offPath} fill="white" />
      )}
    </svg>
  );
}
