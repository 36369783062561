/** @format */

import React from 'react';
import css from './TeacherSPA/TeacherHeader.scss';
import { Popper } from 'react-popper';
import ReactDOM from '@hot-loader/react-dom';

function stopPropagation(e) {
  e.stopPropagation();
}

export function TeacherPopper({ portal, children, ...props }) {
  return (
    <Popper {...props}>
      {({ ref, style }) => {
        const inner = (
          <div
            className={css.headerDropdownPopper}
            style={{ ...style }}
            ref={ref}
            onClick={stopPropagation}
          >
            {children}
          </div>
        );
        return portal
          ? ReactDOM.createPortal(inner, document.getElementById('teacher-react-root'))
          : inner;
      }}
    </Popper>
  );
}
