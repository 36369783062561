/** @format */

import React, { useCallback } from 'react';
import classnames from 'classnames';
import css from './UnderlinedTabs.scss';
import * as ReactTabs from 'react-tabs';
import { style } from '../lib/styling';
import { FlexSeparator } from './FlexSeparator';

export const Tabs = ReactTabs.Tabs;

export const Tab = style(ReactTabs.Tab, css.tabItem);
Tab.tabsRole = 'Tab';

export const TabList = style(ReactTabs.TabList, css.tabs);
TabList.tabsRole = 'TabList';

export const TabPanel = ReactTabs.TabPanel;

function Toggler({
  className,
  activeClassName,
  active,
  onClick,
  value,
  children,
  Component = 'div'
}) {
  const callback = useCallback(() => onClick(value), [value, onClick]);
  return (
    <Component
      className={classnames(className, active === value ? activeClassName : null)}
      onClick={callback}
    >
      {children}
    </Component>
  );
}

export function UnderlinedTabs({ items, active, onChange, children, className, style }) {
  return (
    <ul className={classnames(css.tabs, className)} style={style}>
      {items.map(({ title, value }) => (
        <Toggler
          key={value}
          Component="li"
          onClick={onChange}
          value={value}
          active={active}
          className={css.tabItem}
          activeClassName={css.active}
        >
          {title}
        </Toggler>
      ))}
      <FlexSeparator />
      {children}
    </ul>
  );
}
